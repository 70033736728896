import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { boronClient } from "../../api";
import { HTTPErrors, createError } from "../../errors";
import { StudyRecordWithRestrictedAttributes } from "../../interfaces/StudyRecordInterface";
import { paths } from "../../lib/api/v1";

type Props = {
  studentId: string;
};

export type StudentTimelineResponse =
  paths["/api/v1/students/{student_id}/timeline"]["get"]["responses"]["200"]["content"]["application/json"];

const url = "/api/v1/students/{student_id}/timeline";

export const useFetchStudentTimeline = ({ studentId }: Props) => {
  const {
    data,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    error,
    fetchNextPage,
  } = useInfiniteQuery<StudentTimelineResponse, HTTPErrors>({
    queryKey: [url, studentId],
    queryFn: ({ pageParam }) =>
      fetchStudentTimeline({
        pageParam: pageParam as string | undefined,
        studentId,
      }),
    initialPageParam: null,
    getNextPageParam: ({ studyRecords }) => studyRecords.meta.till,
    enabled: studentId !== "",
  });

  const queryClient = useQueryClient();

  const updateStudyRecordQueryCache = (
    newStudyRecord: StudyRecordWithRestrictedAttributes,
  ) => {
    queryClient.setQueriesData<InfiniteData<StudentTimelineResponse>>(
      { queryKey: [url, studentId] },
      (oldData: InfiniteData<StudentTimelineResponse> | undefined) => {
        if (!oldData) {
          return {
            pages: [],
            pageParams: [],
          } as InfiniteData<StudentTimelineResponse>;
        }

        const result = {
          ...oldData,
          pages: oldData.pages.map((page: any) => {
            return {
              ...page,
              studyRecords: {
                data: page.studyRecords.data.map((oldStudyRecord: any) =>
                  oldStudyRecord.id === newStudyRecord.id
                    ? newStudyRecord
                    : oldStudyRecord,
                ),
                meta: page.studyRecords.meta,
              },
            };
          }),
        };

        return result as InfiniteData<StudentTimelineResponse>;
      },
    );
  };

  return {
    data: data?.pages.flatMap((page) => page.studyRecords.data),
    meta: data?.pages.flatMap((page) => page.studyRecords.meta)[0],
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    error,
    fetchNextPage,
    updateStudyRecordQueryCache,
  };
};

const fetchStudentTimeline = async ({
  studentId,
  pageParam,
}: {
  studentId: string;
  pageParam?: string;
}) => {
  const { data, response } = await boronClient.GET(url, {
    params: {
      query: {
        since: pageParam,
      },
      path: {
        student_id: studentId,
      },
    },
  });

  if (response.ok && data) {
    return data;
  }

  throw await createError(response);
};
