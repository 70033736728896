import classNames from "classnames";
import { differenceInDays } from "date-fns";
import { ErrorMessage, useField } from "formik";
import { DateRangePickerField } from "../../atoms/DateRangePickerField";
import { useMultiSectionBatchesDateRangePicker } from "./useMultiSectionBatchesDateRangePicker";

export const MultiSectionBatchesDownloadDateRangePicker = () => {
  const [field, meta, helpers] = useField("dateRange");
  const { value: dateRange } = field;
  const { touched, error } = meta;
  const { setValue } = helpers;
  const { disabled } = useMultiSectionBatchesDateRangePicker(dateRange);

  const hasError = !!(touched && error);
  return (
    <div className="flex flex-col gap-8 rounded-lg bg-gray-100 px-10 max-md:px-4 py-8">
      <div>
        <p>2. 出力したい期間を選択してください</p>
      </div>
      <div className="flex items-center gap-3 flex-wrap">
        <div
          className="w-[30rem]"
          onClick={() => {
            if (dateRange.startDate && dateRange.endDate) {
              setValue({});
            }
          }}
        >
          <DateRangePickerField
            dateRange={dateRange}
            onChangeDateRange={(dates) => {
              setValue(dates);
            }}
            placeholder="出力期間を選択"
            disabled={disabled}
            className={classNames({
              "bg-white": !hasError,
              "bg-darkred-100": hasError,
            })}
            fromDate={new Date(2024, 3, 1)} // NOTE: 2024/4/1 以降の日付を選択可能にする
            hasError={hasError}
          />
          <ErrorMessage
            name="dateRange"
            component="div"
            className="my-2 text-red-400"
          />
        </div>
        {dateRange.startDate !== undefined &&
          dateRange.endDate !== undefined && (
            <p className="font-bold">
              の{differenceInDays(dateRange.endDate, dateRange.startDate) + 1}
              日間
            </p>
          )}
      </div>
    </div>
  );
};
