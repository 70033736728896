import * as React from "react";
import { useFetchSectionsTimeline } from "../../../../../features/SectionsTimelinePage/useFetchSectionsTimeline";
import { useFetchSectionsTimelineSummary } from "../../../../../features/SectionsTimelineSummaryPage/useFetchSectionsTimelineSummary";
import type {
  StudyRecordCommentInterface,
  StudyRecordWithRestrictedAttributes,
} from "../../../../../interfaces/StudyRecordInterface";

type Props = {
  studyRecord: StudyRecordWithRestrictedAttributes;
  updateStudyRecordQueryCache:
    | ReturnType<
        typeof useFetchSectionsTimelineSummary
      >["updateStudyRecordQueryCache"]
    | ReturnType<
        typeof useFetchSectionsTimeline
      >["updateStudyRecordQueryCache"];
};

export const useTimelineComments = ({
  studyRecord,
  updateStudyRecordQueryCache,
}: Props) => {
  const [isVisible, setIsVisible] = React.useState(
    studyRecord.comments.length > 0,
  );

  const onAddComment = (comment: StudyRecordCommentInterface) => {
    updateStudyRecordQueryCache({
      ...studyRecord,
      comments: [...studyRecord.comments, comment],
    });
  };

  const onDeleteComment = (comment: StudyRecordCommentInterface) => {
    updateStudyRecordQueryCache({
      ...(studyRecord as any),
      comments: studyRecord.comments.filter(
        (prevComment) => prevComment.id !== comment.id,
      ),
    });
  };

  const toggleVisibility = () => {
    setIsVisible((prev) => !prev);
  };

  return {
    comments: studyRecord.comments,
    isVisible,
    onAddComment,
    onDeleteComment,
    toggleVisibility,
    setIsVisible,
  };
};
