import { Link } from "react-router-dom";
import { LearningMaterial } from "../../../features/StudentsLearningMaterialsNewPage";
import StudentInterface from "../../../interfaces/StudentInterface.ts";
import { LearningMaterialList } from "./LearningMaterialList.tsx";
import styles from "./styles.scss";
import { LearningMaterialSearchType } from "./useFetchLearningMaterials.ts";

export const SearchResult = ({
  student,
  handleRegisterLearningMaterial,
  learningMaterials,
  totalCount,
  registeredLearningMaterialCodes,
  registeringLearningMaterialCodes,
  searchType,
}: {
  student: StudentInterface;
  handleRegisterLearningMaterial: (
    learningMaterial: LearningMaterial,
  ) => () => void;
  learningMaterials: LearningMaterial[] | undefined;
  totalCount: number;
  registeredLearningMaterialCodes: string[] | undefined;
  registeringLearningMaterialCodes: string[];
  searchType: LearningMaterialSearchType;
}) => {
  if (!learningMaterials || !registeredLearningMaterialCodes) {
    return null;
  }

  return (
    <>
      {/* 検索結果件数 */}
      <div className={styles.searchResultCount}>
        <strong>検索結果：{totalCount}件</strong>
      </div>

      {/* 検索結果一覧  */}
      {learningMaterials.length > 0 ? (
        <div className={styles.searchResult}>
          <LearningMaterialList
            learningMaterials={learningMaterials}
            registeredLearningMaterialCodes={registeredLearningMaterialCodes}
            handleRegisterLearningMaterial={handleRegisterLearningMaterial}
            registeringLearningMaterialCodes={registeringLearningMaterialCodes}
          />
        </div>
      ) : searchType !== "drill" ? (
        <p className={`mt-7 ${styles.errorMessage}`}>
          該当するテキストが見つかりませんでした。教材を新しく登録もできます。
          <Link
            to={`/sections/${student.section.id}/settings/section_learning_materials/new`}
            className={styles.linkInEmptyResultMessage}
          >
            独自教材の登録はこちら
          </Link>
        </p>
      ) : (
        <p className="mt-7">該当する教材が見つかりませんでした。</p>
      )}
    </>
  );
};
