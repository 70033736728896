import * as React from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from "../../general/Tooltip/Tooltip";
import Icon from "../Icon/index";
import styles from "./styles.scss";

interface Props {
  iconName?: string;
  icon?: React.ReactNode;
  inputAttributes: React.InputHTMLAttributes<Element>;
  className?: string;
  tooltipLabel?: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * input要素をIconで表示して選択できるようにするためのラッパーコンポーネント
 */
const InputIcon: React.FC<Props> = (props: Props) => {
  const handleChange = (e: React.ChangeEvent<any>) => {
    props.onChange(e);

    // NOTE: そのままだと,次に同じファイルを選択したときに発火しない。
    // そこで,target.valueを空文字にすることで次のファイル変更を検知できるようにしている
    e.target.value = "";
  };

  return (
    <>
      <label
        htmlFor={props.inputAttributes.id || ""}
        className={attachParentClassName(props)}
        data-for={`${props.inputAttributes.id}-tooltip`}
        data-tip={true}
        data-testid={`${props.inputAttributes.id}-input-icon-label`}
      >
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger asChild>
              <span data-testid="test-tooltip-icon">
                {props.iconName && (
                  <Icon
                    name={props.iconName}
                    disabled={props.disabled}
                    hoverWithBackground={true}
                  />
                )}
                {props.icon && props.icon}
              </span>
            </TooltipTrigger>
            <TooltipPortal>
              {!props.disabled && (
                <TooltipContent side="top" sideOffset={25}>
                  <span data-testid="test-tooltip-content">
                    <p>{props.tooltipLabel}</p>
                  </span>
                </TooltipContent>
              )}
            </TooltipPortal>
          </Tooltip>
        </TooltipProvider>
      </label>
      <input
        {...props.inputAttributes}
        onChange={handleChange}
        className={styles.input}
        disabled={props.disabled}
        aria-label={props.tooltipLabel}
      />
    </>
  );
};

const attachParentClassName = (props: Props) => {
  if (props.disabled) {
    return props.className ? `${props.className}` : "";
  } else {
    return props.className
      ? `${styles.label} ${props.className}`
      : styles.label;
  }
};
export default InputIcon;
