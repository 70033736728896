import * as React from "react";
import Loader from "../../components/atoms/Loader";
import StudentFilter from "../../components/features/NewStudentFilter";
import { SectionTimelineTab } from "../../components/features/SectionTimelineTab";
import TimelineItem from "../../components/features/Timeline/TimelineItem";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../helpers/RouterHelper";
import {
  AuthenticateRouterProps,
  AuthenticatedPageProps,
} from "../../hocs/enhanceAuthenticatedPage";
import enhanceSectionsPage from "../../hocs/enhanceSectionsPage";
import { useQueryError } from "../../hooks/http/useQueryError";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";
import { useQueryString } from "../../hooks/useQueryString";
import OperatorInterface from "../../interfaces/OperatorInterface";
import SectionInterface from "../../interfaces/SectionInterface";
import { TimelineType } from "../../interfaces/TimelineInterface";
import styles from "./index.scss";
import {
  SectionTimelineResponseData,
  useFetchSectionsTimeline,
} from "./useFetchSectionsTimeline";

interface Props
  extends WithRouterProps<AuthenticateRouterProps>,
    Pick<AuthenticatedPageProps, "studentFilterContext" | "currentOperator">,
    OutletContextProps {
  section: SectionInterface;
}

const SectionsTimelinePage = (props: Props) => {
  React.useLayoutEffect(() => {
    props.setActiveMenu("timeline");
  }, []);

  const { data, isLoading, isFetchingNextPage, updateStudyRecordQueryCache } =
    useSectionsTimelinePage(props);

  if (!props.section || !props.currentOperator) return null;

  return (
    <>
      <SectionTimelineTab
        sectionId={props.section.id}
        timelineType={TimelineType.TIMELINE_REALTIME}
        search={props.location.search}
      />
      <StudentFilter sectionId={props.section.id} />
      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <TimelineRealtimeItems
          currentOperator={props.currentOperator}
          data={data || []}
          isFetchingNextPage={isFetchingNextPage}
          updateStudyRecordQueryCache={updateStudyRecordQueryCache}
        />
      )}
    </>
  );
};

type TimelineRealtimeItemsProps = {
  currentOperator: OperatorInterface;
  data: SectionTimelineResponseData[];
  isFetchingNextPage: boolean;
  updateStudyRecordQueryCache: ReturnType<
    typeof useFetchSectionsTimeline
  >["updateStudyRecordQueryCache"];
};
const TimelineRealtimeItems = ({
  currentOperator,
  data,
  isFetchingNextPage,
  updateStudyRecordQueryCache,
}: TimelineRealtimeItemsProps) => {
  return (
    <div className={styles.root}>
      <div>
        {data && data.length === 0 ? (
          <p className="mt-4 text-center">勉強記録がまだありません</p>
        ) : (
          data?.map((studyRecord) => {
            return (
              <div
                className={styles.timelineItem}
                key={`TimelineRealtimeItem-${studyRecord.id}`}
              >
                <TimelineItem
                  currentOperator={currentOperator}
                  studyRecord={studyRecord}
                  student={studyRecord.student}
                  canSendComment={studyRecord.student.canSendComment}
                  showHeader={true}
                  sectionId={studyRecord.student.section.id}
                  billingPlan={studyRecord.student.billingPlan}
                  updateStudyRecordQueryCache={updateStudyRecordQueryCache}
                />
              </div>
            );
          })
        )}
      </div>
      <Loader loading={isFetchingNextPage} />
    </div>
  );
};

const useSectionsTimelinePage = (props: Props) => {
  const queryParams = useQueryString();
  const {
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    error,
    updateStudyRecordQueryCache,
  } = useFetchSectionsTimeline({
    sectionId: props.params.sectionId,
    queryParams: queryParams,
    enabled: !!props.section,
  });
  useQueryError(error);

  useOnMainScrollAreaScroll(() => {
    if (isLoading || isFetchingNextPage) return;

    fetchNextPage();
  }, [isLoading, isFetchingNextPage, fetchNextPage]);

  return {
    data,
    isLoading,
    isFetchingNextPage,
    updateStudyRecordQueryCache,
  };
};

const pageInfo = {
  title: "タイムライン",
};

export default enhanceSectionsPage(SectionsTimelinePage, pageInfo);
