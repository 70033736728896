import { useQuery, useQueryClient } from "@tanstack/react-query";
import { boronClient } from "../../../api.ts";
import { createError } from "../../../errors.ts";

const buildKey = (studentId: string) => [
  "/api/v1/students/{student_id}/learning_materials/pre_registrations",
  studentId,
];

export const useFetchLearningMaterialPreRegistrations = ({
  studentId,
}: { studentId: string }) =>
  useQuery({
    queryKey: buildKey(studentId),
    queryFn: async () => {
      const { data, response } = await boronClient.GET(
        "/api/v1/students/{student_id}/learning_materials/pre_registrations",
        {
          params: {
            path: {
              student_id: studentId,
            },
          },
        },
      );

      if (response.ok && data) {
        return data.learningMaterials;
      }
      throw await createError(response);
    },
  });

export const useRemoveLearningMaterialPreRegistrationCache = ({
  studentId,
}: {
  studentId: string;
}) => {
  const queryClient = useQueryClient();

  const removeLearningMaterialPreRegistrationCache = (materialCode: string) => {
    queryClient.setQueryData<
      ReturnType<typeof useFetchLearningMaterialPreRegistrations>["data"]
    >(buildKey(studentId), (data) => {
      if (!data) {
        return data;
      }

      return data.filter(
        (learningMaterial) => learningMaterial.code !== materialCode,
      );
    });
  };

  return { removeLearningMaterialPreRegistrationCache };
};
