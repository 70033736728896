import { useMutation } from "@tanstack/react-query";
import ApiClient from "../../../api";
import { HTTPErrors, createError } from "../../../errors.ts";
import { useFlashMessage } from "../../../hooks/useFlashMessage.ts";
import { paths } from "../../../lib/api/v1";
import { useAddStaffBoardPostToListCache } from "../useFetchStaffBoardPosts.ts";

type Response =
  paths["/api/v1/sections/{section_id}/staff_board_posts"]["post"]["responses"]["200"]["content"]["application/json"];
export type RequestBody =
  paths["/api/v1/sections/{section_id}/staff_board_posts"]["post"]["requestBody"]["content"]["multipart/form-data"]["staff_board_post"];

export const useCreateStaffBoardPost = ({
  sectionId,
  onError,
}: {
  sectionId: string;
  onError: (error: HTTPErrors) => void;
}) => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const { addStaffBoardPostToListCache } = useAddStaffBoardPostToListCache({
    sectionId,
  });

  const path = `/api/v1/sections/${sectionId}/staff_board_posts`;
  return useMutation<Response, HTTPErrors, RequestBody>({
    mutationFn: async (params) => {
      const res = await ApiClient.sendFormData(path, "POST", {
        staff_board_post: params,
      });
      if (res.ok) {
        const json = await res.json();
        return json;
      }
      throw await createError(res);
    },
    onSuccess: (responseBody: any) => {
      addStaffBoardPostToListCache(responseBody.staffBoardPost);
      showSuccessMessage("スタッフボードに投稿しました");
    },
    onError: (error) => {
      onError(error);
      showErrorMessage("スタッフボードに投稿できませんでした");
    },
  });
};
