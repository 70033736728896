import * as React from "react";

import { IconNaviMenuClose } from "@studyplus/boron-ui";
import { Overlay } from "../Overlay";
import styles from "./styles.scss";
import { useDrawerContext } from "./useDrawerContext";

export const Drawer = () => {
  const { isOpen, headerComponent, bodyComponent, closeDrawer } =
    useDrawerContext();

  if (!isOpen) return null;

  return (
    <>
      <Overlay onClick={closeDrawer} />
      <DrawerContainer
        closeDrawer={closeDrawer}
        header={headerComponent}
        body={bodyComponent}
      />
    </>
  );
};

type DrawerContainerProps = {
  closeDrawer: () => void;
  header: React.ReactNode;
  body: React.ReactNode;
};
const DrawerContainer = ({
  closeDrawer,
  body,
  header,
}: DrawerContainerProps) => {
  return (
    <div className={styles.drawerContainer}>
      <div className="sticky mb-8">
        {header}
        <IconNaviMenuClose
          className={`fixed right-8 top-8 cursor-pointer text-gray-600 hover:text-blue-400`}
          onClick={closeDrawer}
        />
      </div>
      <div className="overflow-y-auto pb-4">{body}</div>
    </div>
  );
};
