import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { InfiniteData } from "@tanstack/react-query";
import queryString from "query-string";
import { useCallback, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { boronClient } from "../../api";
import { useStudentFilterContext } from "../../components/features/NewStudentFilter/useStudentFilterContext";
import { HTTPErrors, createError } from "../../errors";
import { buildStudentFilterParams } from "../../helpers/FiltersHelper";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import { useQueryError } from "../../hooks/http/useQueryError";
import { OrderDirFilterType } from "../../interfaces/FiltersInterface";
import StudentInterface from "../../interfaces/StudentInterface";
import { paths } from "../../lib/api/v1";

type StudentsResponse =
  paths["/api/v1/sections/{section_id}/students"]["get"]["responses"]["200"]["content"]["application/json"]["students"];

export const useFetchStudents = ({ sectionId }: { sectionId: string }) => {
  const { findTagFilter, filtersQuery } = useStudentFilterContext();
  const studentFilter = findTagFilter({ sectionId: sectionId });
  const selectedTags = [
    ...studentFilter.selectedTags,
    ...studentFilter.selectedStatusTags,
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const orderDirFromSearch = coerceOrderDir(
    queryString.parse(location.search).order_dir,
  );
  const [orderDir, setOrderDir] =
    useState<OrderDirFilterType>(orderDirFromSearch);
  const changeOrder = (orderDir: OrderDirFilterType) => {
    setOrderDir(orderDir);
    navigate({
      search: addSearchParam(location.search, "order_dir", orderDir),
    });
  };
  const nameQuery = {
    first_name: filtersQuery.current?.firstName,
    last_name: filtersQuery.current?.lastName,
  };

  const {
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    error,
  } = useInfiniteQuery<StudentsResponse, HTTPErrors>({
    queryKey: ["students", sectionId, orderDir, selectedTags, nameQuery],
    queryFn: async ({ pageParam }) => {
      const { response, data } = await boronClient.GET(
        "/api/v1/sections/{section_id}/students",
        {
          params: {
            path: {
              section_id: sectionId,
            },
            query: {
              ...buildStudentFilterParams(studentFilter),
              ...nameQuery,
              order_dir: orderDir === "" ? undefined : orderDir,
              page: pageParam as number,
            },
          },
        },
      );

      if (response.ok && data) {
        return data.students;
      }
      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
    refetchOnWindowFocus: false, // reduxだったときにはrefetchしていなかったので合わせる
  });
  useQueryError(error);

  const loadMore = useCallback(() => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  }, [isFetching, hasNextPage, fetchNextPage]);

  const queryClient = useQueryClient();

  const updateStudent = (student: StudentInterface) => {
    queryClient.setQueriesData<InfiniteData<StudentsResponse>>(
      { queryKey: ["students", sectionId] },
      (oldData) => {
        if (!oldData) {
          return oldData;
        }

        return {
          ...oldData,
          pages: oldData.pages.map((page) => {
            return {
              ...page,
              data: page.data.map((oldStudent) => {
                if (oldStudent.id === student.id) {
                  return {
                    guardianEmails:
                      student.guardianEmails ?? oldStudent.guardianEmails,
                    ...student,
                  };
                }
                return oldStudent;
              }),
            };
          }),
        };
      },
    );
  };

  const removeStudent = (studentId: string) => {
    queryClient.setQueriesData<InfiniteData<StudentsResponse>>(
      { queryKey: ["students", sectionId] },
      (oldData) => {
        if (!oldData) {
          return oldData;
        }

        return {
          ...oldData,
          pages: oldData.pages.map((page) => {
            return {
              ...page,
              data: page.data.filter(
                (oldStudent) => oldStudent.id !== studentId,
              ),
            };
          }),
        };
      },
    );
  };

  return {
    data,
    isLoading,
    isFetchingNextPage,
    loadMore,
    updateStudent,
    removeStudent,
    changeOrder,
    orderDir,
  };
};

const coerceOrderDir = (
  orderDir: string | (string | null)[] | null,
): OrderDirFilterType => {
  if (orderDir === "asc" || orderDir === "desc") {
    return orderDir;
  }
  return "";
};

const addSearchParam = (search: string, key: string, value: string) => {
  const searchParams = new URLSearchParams(search);
  searchParams.set(key, value);
  return searchParams.toString();
};
