import classNames from "classnames";
import * as React from "react";
import { ColorProps, resolveColorStyles } from "../../../helpers/styles/Colors";
import styles from "./styles.scss";

// デフォルトの色プロパティ
const defaultColor = "black-lighten-2";
const defaultHoverColor = "primary";
const defaultColorProps: ColorProps = {
  color: defaultColor,
  hoverColor: defaultHoverColor,
};
type Props = {
  children: React.ReactNode;
  bold?: boolean;
} & ColorProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

const LinkButton = React.forwardRef(
  (props: Props, ref: React.Ref<HTMLButtonElement>) => {
    const { children, bold, ...buttonAttributes } = props;
    const className = classNames(styles.root, bold ? styles.bold : null, {
      [styles.disabled]: props.disabled,
    });

    // デフォルトの色プロパティとマージ
    // propsで渡されたほうを優先
    const mergedButtonAttributes = {
      ...defaultColorProps,
      ...buttonAttributes,
    };

    return (
      <button
        onClick={props.onClick}
        className={classNames(
          className,
          resolveColorStyles(mergedButtonAttributes),
        )}
        ref={ref}
        {...buttonAttributes}
      >
        {children}
      </button>
    );
  },
);

export default LinkButton;
