import classNames from "classnames";
import * as React from "react";
import styles from "./styles.scss";

interface Props extends React.PropsWithChildren<unknown> {
  opened: boolean;
  scrollable: boolean;
  collapsible?: boolean;
}

const renderMask = () => <div className={styles.mask} />;

const SidebarLayout: React.FC<Props> = ({
  children,
  opened = true,
  scrollable = true,
  collapsible = true,
}) => {
  return (
    <React.Fragment>
      <div
        className={classNames(styles.root, {
          [styles.opened]: opened,
          [styles.closed]: !opened,
          [styles.scrollable]: scrollable,
          [styles.collapsible]: collapsible,
        })}
      >
        {children}
      </div>
      {opened ? renderMask() : null}
    </React.Fragment>
  );
};

export default SidebarLayout;
