import { Button } from "@studyplus/boron-ui";
import * as React from "react";
import { useState } from "react";
import Select, { CSSObjectWithLabel, OnChangeValue } from "react-select";
import styles from "./styles.scss";
import { LearningMaterialSearchType } from "./useFetchLearningMaterials.ts";

interface SearchTypeOption {
  label: string;
  value: LearningMaterialSearchType;
}

const SEARCH_TYPE_OPTIONS: SearchTypeOption[] = [
  {
    label: "校舎独自教材",
    value: "section_original",
  },
  {
    label: "デジタル教材",
    value: "drill",
  },
  {
    label: "書籍",
    value: "general_books",
  },
  {
    label: "App Store",
    value: "itunes_app",
  },
];

export const SearchForm = ({
  defaultSearchKeyword,
  defaultSearchType,
  isSearching,
  onSearch,
}: {
  defaultSearchKeyword: string;
  defaultSearchType: LearningMaterialSearchType;
  isSearching: boolean;
  onSearch: (params: {
    keyword: string;
    type: LearningMaterialSearchType;
  }) => void;
}) => {
  const [searchKeyword, setSearchKeyword] = useState(defaultSearchKeyword);
  const [searchType, setSearchType] = useState(defaultSearchType);

  const handleChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value);
  };

  const handleChangeSearchType = (
    option: OnChangeValue<SearchTypeOption, false>,
  ) => {
    if (!option) return;

    setSearchType(option.value);
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSearch({
      keyword: searchKeyword,
      type: searchType,
    });
  };

  return (
    <form
      className={styles.searchForm}
      onSubmit={handleSearch}
      aria-label="教材検索"
    >
      <div className={styles.searchForm__type}>
        <label htmlFor="StudentNewLearningMaterial-type">教材種別</label>
        <Select
          inputId="StudentNewLearningMaterial-type"
          options={SEARCH_TYPE_OPTIONS}
          className={styles.searchForm__type__select}
          defaultValue={SEARCH_TYPE_OPTIONS[0]}
          isSearchable={false}
          styles={{
            control: (styles: CSSObjectWithLabel) => ({
              ...styles,
              height: "4.6rem",
            }),
          }}
          onChange={handleChangeSearchType}
          value={SEARCH_TYPE_OPTIONS.find(
            (option) => option.value === searchType,
          )}
        />
      </div>

      <div className={styles.searchForm__name}>
        <label htmlFor="StudentNewLearningMaterial-name">教材名</label>
        <input
          id="StudentNewLearningMaterial-name"
          name="name"
          value={searchKeyword}
          onChange={handleChangeSearchText}
        />
      </div>

      <div className={styles.searchForm__button}>
        <Button
          disabled={isSearching || searchKeyword.length === 0}
          isBlock={true}
          type="submit"
        >
          検索
        </Button>
      </div>
    </form>
  );
};
