import { UnprocessableEntityError } from "../../errors";

type Props = {
  error: Error | null | UnprocessableEntityError;
};

export const ErrorMessagesFromServer = ({ error }: Props) => {
  if (
    error instanceof UnprocessableEntityError &&
    error?.originalErrors !== undefined
  ) {
    return (
      <div>
        {error.originalErrors.map((error) => (
          <p key={error.resource} className="text-red-400">
            {error.message}
          </p>
        ))}
      </div>
    );
  }
  return null;
};
