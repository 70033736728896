import { Link } from "react-router-dom";
import TimeHelper from "../../../../../helpers/TimeHelper";
import StudentInterface from "../../../../../interfaces/StudentInterface";
import type { StudyRecordWithRestrictedAttributes } from "../../../../../interfaces/StudyRecordInterface";
import Icon from "../../../../atoms/Icon";
import { Record } from "./Record";
import { RestrictedContent } from "./RestrictedContent";
import { StudyProgressLink } from "./StudyProgressLink";
import styles from "./styles.scss";

type Props = {
  studyRecord: StudyRecordWithRestrictedAttributes;
  student: StudentInterface;
  sectionId: string;
  showHeader?: boolean;
};

export const Content = ({
  studyRecord,
  student,
  sectionId,
  showHeader,
}: Props) => {
  // NOTE: 現状の仕様では学習量と学習時間を個別に制限することはないので、&&にしている
  const isRestrictedContent =
    studyRecord.restrictedAttributes.includes("numberOfSeconds") &&
    studyRecord.restrictedAttributes.includes("amount");

  return (
    <div className={styles.content}>
      <div>
        {studyRecord.learningMaterial &&
        studyRecord.learningMaterial.imageUrl ? (
          <div className={styles.imageContainer}>
            <div
              className={styles.image}
              style={{
                backgroundImage: `url(${studyRecord.learningMaterial.imageUrl})`,
              }}
            />
          </div>
        ) : (
          <div className={styles.imageContainer}>
            <div className={styles.image} />
          </div>
        )}
      </div>
      <div className={styles.right}>
        <div className={styles.learningMaterial}>
          <div className={styles.title} data-testid="timeline-item-title">
            {studyRecord.learningMaterial
              ? studyRecord.learningMaterial.name
              : "教材なし"}
          </div>
          {studyRecord.externalLink && (
            <a
              href={studyRecord.externalLink}
              target="_blank"
              rel="noreferrer noopener"
              className={styles.externalLink}
            >
              詳細を確認
              <Icon name="icon-external-link" />
            </a>
          )}
        </div>
        <div className="flex gap-4">
          {isRestrictedContent ? (
            <RestrictedContent studyRecord={studyRecord} />
          ) : (
            <Record studyRecord={studyRecord} />
          )}
        </div>
        {studyRecord.learningMaterial &&
          studyRecord.learningMaterial.isTypeDrill && (
            <div className="mb-1 mt-6">
              <StudyProgressLink
                sectionId={sectionId}
                learningMaterialCode={
                  studyRecord.learningMaterial.learningMaterialCode ?? ""
                }
              />
            </div>
          )}
      </div>
      {!showHeader && (
        <div className={styles.recordedAt} data-testid="timeline-item-date">
          <Link to={`/students/${student.id}/study_records/${studyRecord.id}`}>
            {TimeHelper.fullFormat(studyRecord.recordedAt)}
          </Link>
        </div>
      )}
    </div>
  );
};
