import { Link } from "react-router-dom";
import { BillingPlan } from "../../../../domains/BillingPlan";
import TimeHelper from "../../../../helpers/TimeHelper";
import StudentInterface from "../../../../interfaces/StudentInterface";
import type { StudyRecordWithRestrictedAttributes } from "../../../../interfaces/StudyRecordInterface";
import Icon from "../../../atoms/Icon";
import { BillingPlanLabel } from "../../BillingPlanLabel";
import styles from "./styles.scss";

type Props = {
  student: StudentInterface;
  studyRecord: StudyRecordWithRestrictedAttributes;
  billingPlan?: BillingPlan;
};

export const Header = ({ studyRecord, student, billingPlan }: Props) => {
  return (
    <div className={styles.header}>
      <Icon name="icon-navi-avatar" className={styles.icon} />
      <div className={`${styles.name} gap-3`}>
        <Link to={`/students/${student.id}`}>{student.fullName}</Link>
        {billingPlan ? <BillingPlanLabel billingPlan={billingPlan} /> : null}
      </div>
      <div className={styles.recordedAt}>
        <Link to={`/students/${student.id}/study_records/${studyRecord.id}`}>
          {TimeHelper.fullFormat(studyRecord.recordedAt)}
        </Link>
      </div>
    </div>
  );
};
