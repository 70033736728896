import * as React from "react";
import { useEffect } from "react";
import StudentNewLearningMaterial from "../../components/features/StudentNewLearningMaterial";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../helpers/RouterHelper.tsx";
import enhanceStudentsPage from "../../hocs/enhanceStudentsPage.tsx";
import { useQueryError } from "../../hooks/http/useQueryError.ts";
import StudentInterface from "../../interfaces/StudentInterface.ts";
import { useFetchAllPreRegisteredLearningMaterials } from "./useFetchAllPreRegisteredLearningMaterials.tsx";
import { usePreRegisterLearningMaterial } from "./usePreRegisterLearningMaterial.ts";

interface Props
  extends WithRouterProps<{ studentId: string }>,
    OutletContextProps {
  student: StudentInterface;
}

const StudentsLearningMaterialsPreRegistrationsNewPage: React.FC<Props> = (
  props,
) => {
  useEffect(() => {
    props.setActiveMenu("text");
  }, []);

  const {
    data: preRegisteredLearningMaterials,
    isPending: isLoadingPreRegisteredLearningMaterials,
    error,
  } = useFetchAllPreRegisteredLearningMaterials({
    studentId: props.student.id,
  });
  useQueryError(error);

  const {
    registeringLearningMaterialCodes,
    registeredLearningMaterialCodes,
    handleRegister,
  } = usePreRegisterLearningMaterial();

  const allRegisteredLearningMaterialCodes = preRegisteredLearningMaterials
    ? [
        ...preRegisteredLearningMaterials.map(
          (learningMaterial) => learningMaterial.code,
        ),
        ...registeredLearningMaterialCodes,
      ]
    : undefined;

  return (
    <StudentNewLearningMaterial
      student={props.student}
      isLoading={isLoadingPreRegisteredLearningMaterials}
      registeredLearningMaterialCodes={allRegisteredLearningMaterialCodes}
      registeringLearningMaterialCodes={registeringLearningMaterialCodes}
      onRegister={handleRegister}
      isPreRegistration={true}
    />
  );
};

const EnhancedPage = enhanceStudentsPage(
  StudentsLearningMaterialsPreRegistrationsNewPage,
  {
    title: "テキスト",
  },
);

export default EnhancedPage;
