import * as React from "react";
import { useEffect } from "react";
import { OutletContextProps } from "../../helpers/RouterHelper.tsx";
import enhanceStudentsPage from "../../hocs/enhanceStudentsPage.tsx";
import StudentInterface from "../../interfaces/StudentInterface.ts";
import StudentLearningMaterialsPreRegistrations from "./StudentLearningMaterialsPreRegistrations";

interface Props extends OutletContextProps {
  student: StudentInterface;
}

const StudentsLearningMaterialsPreRegistrationsPage: React.FC<Props> = (
  props,
) => {
  useEffect(() => {
    props.setActiveMenu("text");
  }, []);

  return <StudentLearningMaterialsPreRegistrations student={props.student} />;
};

export default enhanceStudentsPage(
  StudentsLearningMaterialsPreRegistrationsPage,
  {
    title: "テキスト",
  },
);
