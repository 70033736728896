import * as React from "react";
import { useState } from "react";
import Loader from "../../../components/atoms/Loader";
import { StaffBoardPostCreateForm } from "../../../components/features/StaffBoardPostCreateForm/index.tsx";
import StaffBoardPostForm from "../../../components/features/StaffBoardPostForm";
import TabsNavi from "../../../components/molecules/TabsNavi";
import { HTTPErrors, isUnprocessableEntityError } from "../../../errors";
import { FSHTTPError } from "../../../errors.ts";
import { isPreReleaseEnvironment } from "../../../helpers/Settings.ts";
import { useDeleteStaffBoardPost } from "../../../hooks/http/useDeleteStaffBoardPost.ts";
import { useFlashMessage } from "../../../hooks/useFlashMessage.ts";
import OperatorInterface from "../../../interfaces/OperatorInterface.ts";
import SectionInterface from "../../../interfaces/SectionInterface.ts";
import { StaffBoardPost } from "../../../interfaces/StaffBoardPostInterface";
import { HTTP_ERROR_MESSAGE } from "../../../reducers";
import { useRemoveStaffBoardPostFromListCache } from "../useFetchStaffBoardPosts.ts";
import StaffBoardPostList from "./StaffBoardPostList";
import {
  RequestBody,
  useCreateStaffBoardPost,
} from "./useCreateStaffBoardPost.tsx";

interface Props {
  section: SectionInterface;
  currentOperator: OperatorInterface | null;
  staffBoardPosts: StaffBoardPost[];
  isLoading: boolean;
}

const SectionStaffBoardPosts = (props: Props) => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const [apiError, setApiError] = useState<any>(null);

  const { mutate: createPost, isPending: isCreatingPost } =
    useCreateStaffBoardPost({
      sectionId: props.section.id,
      onError: (error: HTTPErrors) => {
        if (isUnprocessableEntityError(error)) {
          setApiError({
            message: "ValidationError",
            errors: error.originalErrors
              .filter((err) => err.field && err.message)
              .map((err) => ({
                field: err.field,
                message: err.message,
              })),
          });
        }
      },
    });

  const { mutate: deletePostMutation } = useDeleteStaffBoardPost({
    sectionId: props.section.id,
  });

  const { removeStaffBoardPostFromListCache } =
    useRemoveStaffBoardPostFromListCache({
      sectionId: props.section.id,
    });

  const handleSubmit = (values: RequestBody, resetForm: () => void) => {
    setApiError(null);
    createPost(values, {
      onSuccess: () => {
        resetForm();
      },
    });
  };

  const handleSubmitWithoutApiErrors = (
    values: RequestBody,
    resetForm: () => void,
  ) => {
    createPost(values, {
      onSuccess: () => {
        resetForm();
      },
    });
  };

  const deletePost = (staffBoardPostId: string) => {
    deletePostMutation(
      {
        staffBoardPostId,
      },
      {
        onSuccess: () => {
          removeStaffBoardPostFromListCache(staffBoardPostId);
          showSuccessMessage("投稿を削除しました");
        },
        onError: (error) => {
          if (error instanceof FSHTTPError) {
            showErrorMessage("投稿を削除できませんでした");
          } else {
            showErrorMessage(HTTP_ERROR_MESSAGE);
          }
        },
      },
    );
  };

  if (!props.currentOperator) return null;

  return (
    <React.Fragment>
      <TabsNavi
        tabs={[
          {
            label: "スタッフボード",
            link: `#`,
            active: true,
          },
        ]}
      />
      {isPreReleaseEnvironment() ? (
        <StaffBoardPostCreateForm
          submitting={isCreatingPost}
          operatorName={props.currentOperator.fullName}
          onSubmit={handleSubmit}
          apiError={apiError}
        />
      ) : (
        <StaffBoardPostForm
          submitting={isCreatingPost}
          operatorName={props.currentOperator.fullName}
          onSubmit={handleSubmitWithoutApiErrors}
        />
      )}
      <StaffBoardPostList
        section={props.section}
        currentOperator={props.currentOperator}
        staffBoardPosts={props.staffBoardPosts}
        deletePost={deletePost}
      />
      <Loader loading={props.isLoading} />
    </React.Fragment>
  );
};

export default SectionStaffBoardPosts;
