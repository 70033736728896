import ContentBox from "../../../components/atoms/ContentBox";
import { BreadcrumbsView } from "../../../components/general/BreadcrumbsView";
import { MultiSectionBatch } from "../useMultiSectionBatchesDetail";
import { Failure } from "./Failure";
import { Pending } from "./Pending";
import { Success } from "./Success";
import { getMultiSectionBatchInfo } from "./getMultiSectionBatchInfo";

type Props = {
  batch: MultiSectionBatch;
};

const DownloadStatus = ({
  batch,
  dataTypeLabel,
  fileNamePrefix,
}: {
  batch: MultiSectionBatch;
  dataTypeLabel: string;
  fileNamePrefix: string;
}) => {
  switch (batch.status) {
    case "standby":
    case "processing":
      return <Pending dataTypeLabel={dataTypeLabel} />;
    case "success":
      return (
        <Success
          batch={batch}
          dataTypeLabel={dataTypeLabel}
          fileNamePrefix={fileNamePrefix}
        />
      );
    case "failure":
      return <Failure dataTypeLabel={dataTypeLabel} />;
    default:
      throw "エラーが発生しました";
  }
};

export const Detail = ({ batch }: Props) => {
  const { batchTypeHuman, dataTypeLabel, fileNamePrefix, Icon } =
    getMultiSectionBatchInfo(batch.batchType);

  const breadcrumbItems = [
    { label: "校舎横断操作", url: "/multi_section_batches" },
    { label: batchTypeHuman },
  ];

  return (
    <div className="flex md:w-[90%] flex-col">
      <div className="py-5">
        <BreadcrumbsView items={breadcrumbItems} />
      </div>
      <ContentBox className="md:w-[80%] md:p-10">
        <div>
          <div className="flex items-center gap-6">
            <Icon className="h-[3rem] w-[3rem] text-gray-600" />
            <h2 className="text-xl">{batchTypeHuman}</h2>
          </div>
          <DownloadStatus
            batch={batch}
            dataTypeLabel={dataTypeLabel}
            fileNamePrefix={fileNamePrefix}
          />
        </div>
      </ContentBox>
    </div>
  );
};
