import { useQuery, useQueryClient } from "@tanstack/react-query";
import { boronClient } from "../../api.ts";
import { createError } from "../../errors.ts";
import { paths } from "../../lib/api/v1";

const path =
  "/api/v1/sections/{section_id}/staff_board_posts/{staff_board_post_hashid}";

type StaffBoardPostResponse =
  paths[typeof path]["get"]["responses"]["200"]["content"]["application/json"]["staffBoardPost"];

type StaffBoardPostCommentResponse =
  paths[typeof path]["get"]["responses"]["200"]["content"]["application/json"]["staffBoardPost"]["comments"][0];

const buildKey = (sectionId: string, staffBoardPostId: string) => [
  "staffBoardPost",
  sectionId,
  staffBoardPostId,
];

export const useFetchStaffBoardPost = ({
  sectionId,
  staffBoardPostId,
  disableCache,
}: {
  sectionId: string;
  staffBoardPostId: string;
  disableCache?: boolean;
}) => {
  return useQuery({
    queryKey: buildKey(sectionId, staffBoardPostId),
    queryFn: async () => {
      const { response, data } = await boronClient.GET(path, {
        params: {
          path: {
            section_id: sectionId,
            staff_board_post_hashid: staffBoardPostId,
          },
        },
      });
      if (response.ok && data) {
        return data.staffBoardPost;
      }
      throw await createError(response);
    },
    gcTime: disableCache ? 0 : 5 * 60 * 1000, // default 5min
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useAddStaffBoardPostCommentToSingleCache = ({
  sectionId,
  staffBoardPostId,
}: {
  sectionId: string;
  staffBoardPostId: string;
}) => {
  const queryClient = useQueryClient();
  const addStaffBoardPostCommentToSingleCache = (
    staffBoardPostComment: StaffBoardPostCommentResponse,
  ) => {
    queryClient.setQueryData<StaffBoardPostResponse>(
      buildKey(sectionId, staffBoardPostId),
      (data) => {
        if (!data) return;

        return {
          ...data,
          comments: [...data.comments, staffBoardPostComment],
        };
      },
    );
  };

  return { addStaffBoardPostCommentToSingleCache };
};

export const useRemoveStaffBoardPostCommentFromSingleCache = ({
  sectionId,
  staffBoardPostId,
}: {
  sectionId: string;
  staffBoardPostId: string;
}) => {
  const queryClient = useQueryClient();
  const removeStaffBoardPostCommentFromSingleCache = (commentId: string) => {
    queryClient.setQueryData<StaffBoardPostResponse>(
      buildKey(sectionId, staffBoardPostId),
      (data) => {
        if (!data) return;

        return {
          ...data,
          comments: data.comments.filter(
            (comment: StaffBoardPostCommentResponse) =>
              comment.id !== commentId,
          ),
        };
      },
    );
  };

  return { removeStaffBoardPostCommentFromSingleCache };
};
