import { useField } from "formik";
import { InputFileList } from "../StaffBoardPost/InputFileList";
import { FormValues } from "./useStaffBoardPostEditForm";

export const InputStaffBoardAttachments = () => {
  const [field, _, helpers] =
    useField<FormValues["postAttachments"]>("postAttachments");
  const { value: inputFiles } = field;
  const { setValue } = helpers;

  const addInputFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setValue([...inputFiles, { file }]);
  };

  const removeInputFile = (index: number) => {
    setValue(inputFiles.filter((_, i) => i !== index));
  };

  return (
    <InputFileList
      inputFiles={inputFiles.map((attachment) => attachment.file)}
      addInputFile={addInputFile}
      removeInputFile={removeInputFile}
    />
  );
};
