import * as React from "react";
import Loader from "../../components/atoms/Loader";
import StudentFilter from "../../components/features/NewStudentFilter";
import { SectionTimelineTab } from "../../components/features/SectionTimelineTab";
import { Drawer } from "../../components/general/Drawer";
import { SingleDatePickerWrapper } from "../../components/molecules/SingleDatePickerWrapper";
import { useSingleDateFilterContext } from "../../contexts/SingleDateFilterContext";
import DeviceHelper from "../../helpers/DeviceHelper";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../helpers/RouterHelper";
import { toStringFromDate } from "../../helpers/TimeHelper";
import {
  AuthenticateRouterProps,
  AuthenticatedPageCallbacks,
  AuthenticatedPageProps,
} from "../../hocs/enhanceAuthenticatedPage";
import enhanceSectionsPage from "../../hocs/enhanceSectionsPage";
import { useQueryError } from "../../hooks/http/useQueryError";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";
import { usePushHistory } from "../../hooks/usePushHistory";
import { useQueryString } from "../../hooks/useQueryString";
import SectionInterface from "../../interfaces/SectionInterface";
import { TimelineType } from "../../interfaces/TimelineInterface";
import TimelineSummaryItem from "./TimelineSummaryItem";
import styles from "./styles.scss";
import { useFetchSectionsTimelineSummary } from "./useFetchSectionsTimelineSummary";

type Props = WithRouterProps<AuthenticateRouterProps> &
  AuthenticatedPageCallbacks &
  Pick<AuthenticatedPageProps, "currentOperator"> &
  OutletContextProps & {
    section: SectionInterface;
  };

const SectionsTimelineSummaryPage = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("timeline");
  }, []);

  const {
    date,
    data,
    isLoading,
    isFetchingNextPage,
    onDateChange,
    updateStudyRecordQueryCache,
  } = useSectionsTimelineSummaryPage(props);

  if (!props.section || !props.currentOperator) return null;

  return (
    <>
      <SectionTimelineTab
        sectionId={props.section.id}
        timelineType={TimelineType.TIMELINE_SUMMARY}
        search={props.location.search}
      />
      <StudentFilter sectionId={props.section.id} />
      <div className={styles.datePicker}>
        <SingleDatePickerWrapper date={date} onDateChange={onDateChange} />
      </div>

      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <ItemsContainerSwitcher>
          {!data || data.length === 0 ? (
            <p className="mt-7 text-center">勉強記録がまだありません</p>
          ) : (
            data.map((summary) => {
              return (
                <TimelineSummaryItem
                  currentOperator={props.currentOperator!}
                  summary={summary}
                  abTestCodes={props.section.abTestCodes}
                  key={`TimelineSummaryItem-${summary.id}`}
                  updateStudyRecordQueryCache={updateStudyRecordQueryCache}
                />
              );
            })
          )}

          {isFetchingNextPage && <Loader loading={isFetchingNextPage} />}
        </ItemsContainerSwitcher>
      )}
    </>
  );
};

const useSectionsTimelineSummaryPage = (props: Props) => {
  const queryParams = useQueryString();
  const { pushHistory } = usePushHistory();
  const { date, setStartOfDate, setStartOfToday } =
    useSingleDateFilterContext();
  React.useEffect(() => {
    if (queryParams.date) {
      setStartOfDate(new Date(queryParams.date));
    }
  }, []);
  React.useEffect(() => {
    if (queryParams.date) {
      setStartOfDate(new Date(queryParams.date));
    } else {
      setStartOfToday();
    }
  }, [queryParams.date]);
  const {
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    error,
    updateStudyRecordQueryCache,
  } = useFetchSectionsTimelineSummary({
    sectionId: props.params.sectionId,
    queryParams: queryParams,
    enabled: !!props.section,
  });
  useQueryError(error);

  useOnMainScrollAreaScroll(() => {
    if (isLoading || isFetchingNextPage) return;

    fetchNextPage();
  }, [isLoading, isFetchingNextPage, fetchNextPage]);

  const onDateChange = (date?: Date) => {
    if (date) {
      pushHistory({ date: toStringFromDate(date) });
    } else {
      pushHistory({});
    }
  };

  return {
    date,
    data,
    isLoading,
    isFetchingNextPage,
    onDateChange,
    updateStudyRecordQueryCache,
  };
};

const ItemsContainerSwitcher = ({ children }: React.PropsWithChildren) => {
  if (DeviceHelper.isScreenTablet()) {
    return (
      <>
        <div className={`${styles.itemsContainer}, m-0`}>{children}</div>
        <Drawer />
      </>
    );
  } else {
    return <div className={styles.itemsContainer}>{children}</div>;
  }
};
const pageInfo = {
  title: "タイムライン",
};

export default enhanceSectionsPage(SectionsTimelineSummaryPage, pageInfo);
