import * as React from "react";
import BackLink from "../../components/atoms/BackLink";
import { MainSection, PageHeader } from "../../components/atoms/MainSection";
import { OutletContextProps } from "../../helpers/RouterHelper";
import enhanceSectionsSettingsPage, {
  SectionsSettingsPageProps,
} from "../../hocs/enhanceSectionsSettingsPage";
import { DrillRegistrationCodeForm } from "./DrillRegistrationCodeForm";
import { MAX_DRILL_REGISTRATION_CODE_COUNT } from "./drillRegistrationCode";
import { UsePostDrillRegistrationCodesProps } from "./usePostDrillRegistrationCodes";

type Props = SectionsSettingsPageProps &
  UsePostDrillRegistrationCodesProps &
  OutletContextProps;

const SectionsSettingsDigitalLearningMaterialsNew = ({
  section,
  setActiveMenu,
}: Props) => {
  React.useEffect(() => {
    setActiveMenu("learning_material");
  }, []);

  return (
    <div className="mt-6">
      <BackLink>戻る</BackLink>
      <div className="mt-7">
        <MainSection className="px-10">
          <PageHeader helpUrl="https://fs-help.studyplus.co.jp/ja/articles/7150785-%E3%83%87%E3%82%B8%E3%82%BF%E3%83%AB%E6%95%99%E6%9D%90%E3%81%AE%E7%99%BB%E9%8C%B2">
            デジタル教材の登録
          </PageHeader>
          <div>
            <div className="mt-7 flex flex-col gap-1">
              <p className="text-lg text-black">
                「デジタル教材登録コード」を入力することでデジタル教材の登録ができます。
              </p>
              <p className="text-sm text-black">
                ※ 1行に1つのコードを入力してください（最大
                {MAX_DRILL_REGISTRATION_CODE_COUNT}件）
              </p>
            </div>
            <div className="mt-9">
              <DrillRegistrationCodeForm section={section} />
            </div>
          </div>
        </MainSection>
      </div>
    </div>
  );
};

const pageInfo = {
  title: "デジタル教材の登録",
};

export const SectionsSettingsDigitalLearningMaterialsNewPage =
  enhanceSectionsSettingsPage(
    SectionsSettingsDigitalLearningMaterialsNew,
    pageInfo,
  );
