import { Button } from "@studyplus/boron-ui";
import { format } from "date-fns";
import { MultiSectionBatch } from "../useMultiSectionBatchesDetail";
import { useDownloadFile } from "./useDownloadFile";

type Props = {
  batch: MultiSectionBatch;
  dataTypeLabel: string;
  fileNamePrefix: string;
};

export const Success = ({
  batch: { id, endedAt, isDownloadFileExpired },
  dataTypeLabel,
}: Props) => {
  const { downloadFile } = useDownloadFile({
    batchId: id,
  });

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-8 rounded-lg bg-gray-100 px-10 py-[22px]">
        <div className="flex flex-col gap-8">
          <p className="font-bold text-blue-400">
            {dataTypeLabel}の作成に成功しました（{" "}
            {format(new Date(endedAt as string), "yyyy/M/d HH:mm")}）
          </p>
          <p>
            作成された{dataTypeLabel}は、以下からダウンロードしてください。
            <span className="text-gray-700">
              （ダウンロードにしばらく時間がかかることがあります）
            </span>
          </p>
          {isDownloadFileExpired ? (
            <p className="text-red-400">
              データの保存期間（1週間）を過ぎたため、ダウンロードができなくなりました。
            </p>
          ) : (
            <p>※データの保存期間は1週間です。</p>
          )}
          <span>
            <Button
              variant="outline"
              isRound
              onClick={downloadFile}
              disabled={isDownloadFileExpired}
            >
              ダウンロード
            </Button>
          </span>
        </div>
      </div>
      <div className="flex justify-end">
        <a
          href="/multi_section_batches"
          className="buiButtonBase buiButtonVariantFilled hover:border-blue-300 hover:bg-blue-300"
        >
          操作一覧に戻る
        </a>
      </div>
    </div>
  );
};
