import queryString from "query-string";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { initializeFiltersByQuery } from "../../actions/common/filters";
import { toQueryString } from "../../helpers/TimeHelper";
import AppStateInterface from "../../interfaces/AppStateInterface";
import FiltersInterface from "../../interfaces/FiltersInterface";
import { useCurrentSection } from "./useCurrentSection";

/**
 * Reduxのstoreにある"dateFilter"(単発の日付)だけを扱いたい時のユーティリティhooks
 * tagFilterやdateRangeFilterも必要なケースには対応していない
 */
type Props = {
  /* ページによっては他のコンポーネント(StudentFilterWatcherとか)がInitialzeFiltersByQueryを呼ぶので重複しないようにskipできるオプション */
  skipInitialzeFiltersByQuery?: boolean;
};

export const useDateFilter = (props?: Props) => {
  const dateFilter = useSelector<
    AppStateInterface,
    FiltersInterface["dateFilter"]
  >((state) => {
    const currentFilter = state.filters.find(
      (filter) => filter.sectionId === state.currentSection.data?.id,
    );
    return currentFilter?.dateFilter;
  });

  const [dateParam, setDateParam] = React.useState(dateFilter);

  const currentSection = useCurrentSection();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dispatchInitializeFiltersByQuery = () => {
    if (!props?.skipInitialzeFiltersByQuery && currentSection.data?.id)
      dispatch(
        initializeFiltersByQuery({
          query: location.search,
          allTags: [],
          sectionId: currentSection.data.id,
        }),
      );
  };

  React.useEffect(() => {
    dispatchInitializeFiltersByQuery();
  }, [dateParam]);

  const setDateFilter = React.useCallback(
    (date: Date) => {
      const urlQuery = queryString.stringify({
        date: toQueryString(date),
      });
      navigate(`${location.pathname}?${urlQuery}`);
      setDateParam(date);
    },
    [dateFilter],
  );

  return {
    dateFilter,
    setDateFilter,
  };
};
