import { boronClient, makeFormData } from "../../../api.ts";
import { HTTPErrors, UnprocessableEntityError } from "../../../errors.ts";
import compressImage from "../../../helpers/compressImage.ts";
import { useBoronMutation } from "../../../hooks/http/useBoronMutation.ts";
import { useUpdateMessageThreadLatestMessage } from "../../../hooks/http/useFetchMessageThreads.ts";
import { useInsertDirectMessageCache } from "../../../hooks/http/useFetchStudentMessages.ts";
import { useFlashMessage } from "../../../hooks/useFlashMessage.ts";
import { paths } from "../../../lib/api/v1";
import { useFormStates } from "./useFormStates.ts";

type MessageResponse =
  paths["/api/v1/students/{student_id}/direct_messages"]["post"]["responses"]["200"]["content"]["application/json"]["message"];
type ZoomMeetingMessageRequestBody =
  paths["/api/v1/students/{student_id}/direct_messages/zoom_meetings"]["post"]["requestBody"]["content"]["application/json"];
type MessageRequestBody =
  paths["/api/v1/students/{student_id}/direct_messages"]["post"]["requestBody"]["content"]["multipart/form-data"];

const MESSAGE_POST_ERROR = "メッセージを送信できませんでした";

type Props = {
  sectionId: string;
  studentId: string;
};

export const useDirectMessageForm = (props: Props) => {
  const { showErrorMessage, showSuccessMessage } = useFlashMessage();

  const { updateMessageThreadLatestMessageByStudentId } =
    useUpdateMessageThreadLatestMessage({
      sectionId: props.sectionId,
    });

  const { insertDirectMessageCache } = useInsertDirectMessageCache();

  const {
    formState,
    setFormState,
    resetValues,
    handleChangeFile,
    handleChangeContent,
  } = useFormStates(props);

  const successPostMessage = (message: MessageResponse) => {
    showSuccessMessage("メッセージを送信しました");
    updateMessageThreadLatestMessageByStudentId(props.studentId, {
      lastReadAt: new Date().toISOString(),
      latestMessage: message,
    });
    insertDirectMessageCache(props.studentId, message);
  };

  const { mutate: postMessage } = useBoronMutation(
    async (params: { content: string } | { file: File }) =>
      await postMessageRequest({
        studentId: props.studentId,
        body: await buildMessageBody(params),
      }),
    {
      onMutate: () => {
        setFormState({ submitting: true });
      },
      onSuccess: (response) => {
        successPostMessage(response.message);
        resetValues();
      },
      onError: (error: HTTPErrors | ImageCompressError) => {
        showErrorMessage(MESSAGE_POST_ERROR);
        if (error instanceof ImageCompressError) {
          setFormState({
            apiErrors: [
              {
                field: "content",
                message:
                  "画像ファイルのファイル形式はimage/jpeg, image/gif, image/pngにしてください。",
              },
            ],
          });
        } else if (error instanceof UnprocessableEntityError) {
          setFormState({ apiErrors: error.originalErrors });
        }
      },
      onSettled: () => {
        setFormState({ submitting: false });
      },
    },
  );

  const { mutate: postZoomMeetingMessage } = useBoronMutation(
    async (body: ZoomMeetingMessageRequestBody) =>
      await boronClient.POST(
        "/api/v1/students/{student_id}/direct_messages/zoom_meetings",
        {
          params: {
            path: { student_id: props.studentId },
          },
          body,
        },
      ),
    {
      onMutate: () => {
        setFormState({ submitting: true });
      },
      onSuccess: (responseBody) => {
        successPostMessage(responseBody.message);
        resetValues();
      },
      onError: (error) => {
        showErrorMessage(MESSAGE_POST_ERROR);
        if (error instanceof UnprocessableEntityError) {
          setFormState({ apiErrors: error.originalErrors });
        }
      },
      onSettled: () => {
        setFormState({ submitting: false });
      },
    },
  );

  const handlePostMessage = (content: string) => {
    postMessage({ content });
  };
  const handlePostMessageFile = (file: File) => {
    postMessage({ file });
  };

  const handlePostZoomMeetingMessage = (
    params: ZoomMeetingMessageRequestBody,
    setSubmitting: (submitting: boolean) => void,
    setErrors: (errors: Record<string, unknown>) => void,
    onSuccessCallback: () => void,
  ): void => {
    setSubmitting(true);
    postZoomMeetingMessage(params, {
      onSettled: () => setSubmitting(false),
      onSuccess: onSuccessCallback,
      onError: (error) => {
        if (error instanceof UnprocessableEntityError) {
          const errors: Record<string, any> = [];
          error.originalErrors.forEach((error) => {
            if (error.field) {
              errors[error.field] = error.message;
            }
          });
          setErrors(errors);
        }
      },
    });
  };
  return {
    formState,
    handleChangeFile,
    handleChangeContent,
    handlePostMessage,
    handlePostMessageFile,
    handlePostZoomMeetingMessage,
  };
};

const prepareImageForUpload = async (image: File) => {
  try {
    return await compressImage(image);
  } catch {
    throw new ImageCompressError();
  }
};

const buildMessageBody = async (
  params: { content: string } | { file: File },
) =>
  "file" in params
    ? params.file.type === "application/pdf"
      ? { file: params.file }
      : {
          image: await prepareImageForUpload(params.file),
        }
    : { content: params.content };

const postMessageRequest = async ({
  studentId,
  body,
}: {
  studentId: string;
  body: MessageRequestBody;
}) => {
  return await boronClient.POST(
    "/api/v1/students/{student_id}/direct_messages",
    {
      params: {
        path: { student_id: studentId },
      },
      body,
      bodySerializer:
        "file" in body || "image" in body ? makeFormData : undefined,
    },
  );
};

// 画像圧縮に失敗した場合のエラー
class ImageCompressError extends Error {
  constructor() {
    super("Failed to compress image");
    // 下記の行はTypeScriptの出力ターゲットがES2015より古い場合(ES3, ES5)のみ必要
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
