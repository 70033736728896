import { useMutation } from "@tanstack/react-query";
import ApiClient from "../../../api";
import { HTTPErrors, createError } from "../../../errors.ts";
import { paths } from "../../../lib/api/v1";

const path =
  "/api/v1/sections/{section_id}/staff_board_posts/{staff_board_post_hashid}";

type RequestBody =
  paths[typeof path]["patch"]["requestBody"]["content"]["multipart/form-data"]["staff_board_post"];

export const useUpdateStaffBoardPost = ({
  sectionId,
  staffBoardPostId,
  onSuccess,
  onError,
}: {
  sectionId: string;
  staffBoardPostId: string;
  onSuccess: () => void;
  onError: (error: HTTPErrors) => void;
}) => {
  const actualPath = path
    .replace("{section_id}", sectionId)
    .replace("{staff_board_post_hashid}", staffBoardPostId);

  return useMutation<Response, HTTPErrors, RequestBody>({
    mutationFn: async (params) => {
      const res = await ApiClient.sendFormData(actualPath, "PATCH", {
        staff_board_post: params,
      });

      if (res.ok) {
        const json = await res.json();
        return json;
      }

      throw await createError(res);
    },
    onSuccess,
    onError,
  });
};
