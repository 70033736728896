import { useQuery } from "@tanstack/react-query";
import { boronClient } from "../../api.ts";
import { createError } from "../../errors.ts";

export const useVerifyResetPasswordToken = ({
  resetPasswordToken,
}: {
  resetPasswordToken: string;
}) =>
  useQuery({
    queryKey: [
      "/api/v1/password_reset/{operator_reset_password_token}",
      resetPasswordToken,
    ],
    queryFn: async () => {
      const { response } = await boronClient.GET(
        "/api/v1/password_reset/{operator_reset_password_token}",
        {
          params: {
            path: {
              operator_reset_password_token: resetPasswordToken,
            },
          },
        },
      );
      if (response.ok) {
        return true;
      }
      throw await createError(response);
    },
    retry: false,
  });
