import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { IconEdit, IconEllipsis, IconTrash } from "@studyplus/boron-ui";
import * as React from "react";
import { HandleThunkActionCreator } from "react-redux";
import { Link } from "react-router-dom";
import { RegexpHelper } from "../../../helpers/RegexpHelper.ts";
import { isPreReleaseEnvironment } from "../../../helpers/Settings.ts";
import TimeHelper from "../../../helpers/TimeHelper.ts";
import OperatorInterface from "../../../interfaces/OperatorInterface.ts";
import SectionInterface from "../../../interfaces/SectionInterface.ts";
import type { StaffBoardPost } from "../../../interfaces/StaffBoardPostInterface.ts";
import Icon from "../../atoms/Icon";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
} from "../../general/DropdownMenu/DropdownMenu.tsx";
import CommentItem from "../../molecules/CommentItem";
import { StaffBoardPostAttachments } from "./StaffBoardPostAttachments.tsx";
import StaffBoardPostCommentForm from "./StaffBoardPostCommentForm";
import styles from "./styles.scss";
import { useDeleteStaffBoardPostComment } from "./useDeleteStaffBoardPostComment.ts";
const nl2br = require("react-nl2br");
const reactStringReplace = require("react-string-replace");

type Props = {
  section: SectionInterface;
  currentOperator: OperatorInterface;
  staffBoardPost: StaffBoardPost;
  deletePost: HandleThunkActionCreator<() => void>;
};

const StaffBoardPostItem = (props: Props) => {
  const { mutate: deleteComment, isPending: isDeletingComment } =
    useDeleteStaffBoardPostComment({
      sectionId: props.section.id,
      staffBoardPostId: props.staffBoardPost.id,
    });

  return (
    <div className={styles.root}>
      <div className={styles.main}>
        <StaffBoardPost
          staffBoardPost={props.staffBoardPost}
          section={props.section}
          deletePost={props.deletePost}
          deleteComment={deleteComment}
        />
      </div>
      <div className={styles.footer}>
        <StaffBoardPostCommentForm
          sectionId={props.section.id}
          staffBoardPost={props.staffBoardPost}
          currentOperator={props.currentOperator}
          isDeletingComment={isDeletingComment}
        />
      </div>
    </div>
  );
};

const StaffBoardPost = ({
  staffBoardPost,
  section,
  deletePost,
  deleteComment,
}: Pick<Props, "staffBoardPost" | "section" | "deletePost"> & {
  deleteComment: ({ commentId }: { commentId: string }) => void;
}) => {
  const regexp = RegexpHelper.URL;

  return (
    <React.Fragment>
      <div className={styles.content}>
        <div className={styles.meta}>
          <p>
            {TimeHelper.japaneseFormatFullDateTime(staffBoardPost.postedAt)}
          </p>
          <div className={styles.right}>
            <Icon name="icon-account" className={styles.svg} />
            <p className={styles.sectionName}>{staffBoardPost.name}</p>
            <PostOptions
              staffBoardPost={staffBoardPost}
              section={section}
              deletePost={deletePost}
            />
          </div>
        </div>
        <div className={styles.title}>
          <Link
            to={`/sections/${section.id}/staff_board_posts/${staffBoardPost.id}`}
          >
            <p>{staffBoardPost.title}</p>
          </Link>
        </div>
        <div className={styles.body}>
          {nl2br(staffBoardPost.comment).flatMap((line: string, i: number) =>
            reactStringReplace(line, regexp, (match: string, n: number) => (
              <a
                className={styles.link}
                href={match}
                target="_blank"
                rel="noopener noreferrer"
                key={`staffBoardPost-link-${i}-${n}`}
              >
                {match}
              </a>
            )),
          )}
          {isPreReleaseEnvironment() && (
            <StaffBoardPostAttachments
              attachments={staffBoardPost.attachments ?? []}
            />
          )}
        </div>
        <CommentIcon staffBoardPost={staffBoardPost} />
      </div>
      <div className={styles.comments}>
        <CommentList
          staffBoardPost={staffBoardPost}
          deleteComment={deleteComment}
        />
      </div>
    </React.Fragment>
  );
};

const PostOptions = ({
  staffBoardPost,
  section,
  deletePost,
}: Pick<Props, "staffBoardPost" | "section" | "deletePost">) => {
  const handleDeletePost = () => {
    if (window.confirm("本当に削除してよろしいですか？")) {
      deletePost();
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        aria-label={`${staffBoardPost.title}のオプション`}
        className="text-gray-600 hover:text-blue-400"
      >
        <IconEllipsis />
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent side="left" align="start">
          <DropdownMenuItem asChild>
            <a
              className="group flex flex-nowrap items-center justify-center gap-3"
              href={`/sections/${section.id}/staff_board_posts/${staffBoardPost.id}/edit`}
            >
              <IconEdit className="text-gray-600 group-hover:text-blue-400" />
              <span className="text-sm">編集</span>
            </a>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <button
              className="group flex flex-nowrap items-center justify-center gap-3"
              onClick={handleDeletePost}
            >
              <IconTrash className="gap-2 text-gray-600 group-hover:text-blue-400" />
              <span className="text-sm">削除</span>
            </button>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  );
};

const CommentIcon = ({ staffBoardPost }: Pick<Props, "staffBoardPost">) => {
  if (staffBoardPost.comments.length === 0) {
    return (
      <span className={styles.commentIcon}>
        <Icon name="icon-action-comment-default" className={styles.icon} />
        コメント
      </span>
    );
  } else {
    return (
      <span className={`${styles.commentIcon} ${styles.active}`}>
        <Icon
          name="icon-action-comment-selected"
          className={`${styles.icon}`}
        />
        {staffBoardPost.comments.length}
      </span>
    );
  }
};

const CommentList = ({
  staffBoardPost,
  deleteComment,
}: {
  staffBoardPost: StaffBoardPost;
  deleteComment: ({ commentId }: { commentId: string }) => void;
}) => {
  return (
    <>
      {staffBoardPost.comments.map((comment) => (
        <CommentItem
          senderName={comment.name}
          createdAt={comment.postedAt}
          comment={comment.comment}
          onDelete={() => deleteComment({ commentId: comment.id })}
          key={`staff_board_comment-${staffBoardPost.id}=${comment.id}`}
        />
      ))}
    </>
  );
};

export default StaffBoardPostItem;
