import { StudentLearningMaterialsPreRegistrationsStateInterface } from "../../../interfaces/StudentLearningMaterialsPreRegistrationsStateInterface";

const defaultState = {
  data: null,
  loading: false,
  submitting: false,
};

const studentLearningMaterialsPreRegistrations = (
  state: StudentLearningMaterialsPreRegistrationsStateInterface = defaultState,
  action: ReduxActions.Action<any>,
) => {
  switch (action.type) {
    case "PRE_REGISTER_LEARNING_MATERIAL_TO_BOOKSHELF":
      return {
        ...state,
        submitting: true,
      };

    case "PRE_REGISTER_LEARNING_MATERIAL_TO_BOOKSHELF_SUCCESS":
    case "PRE_REGISTER_LEARNING_MATERIAL_TO_BOOKSHELF_ERROR":
      return {
        ...state,
        submitting: false,
      };

    default:
      return state;
  }
};

export default studentLearningMaterialsPreRegistrations;
