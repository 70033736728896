import { IconLock } from "@studyplus/boron-ui";

type Props = {
  firstName: string;
  lastName: string;
  sectionId: string;
  description?: string;
  iconClassName?: string;
};

export const StudentFreePlanNotice = ({
  firstName,
  lastName,
  sectionId,
  description = "にすると全ての学習時間の閲覧ができます。",
  iconClassName = "h-3 w-3",
}: Props) => {
  const path = `/sections/${sectionId}/settings/students?first_name=${firstName}&last_name=${lastName}`;

  return (
    <p className="flex items-end justify-center gap-1 text-center text-gray-800">
      <IconLock
        className={`inline-block shrink-0 grow-0 text-gray-600 ${iconClassName}`}
      />
      <span>
        <a href={path} className="underline">
          プロプラン
        </a>
        {description}
      </span>
    </p>
  );
};
