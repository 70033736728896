import { useNavigate } from "react-router-dom";
import { boronClient } from "../../api";
import { isUnprocessableEntityError } from "../../errors.ts";
import { useBoronMutation } from "../../hooks/http/useBoronMutation";
import { useFlashMessage } from "../../hooks/useFlashMessage.ts";

export const useResetPassword = () => {
  const navigate = useNavigate();
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const mutation = useBoronMutation(
    async ({
      token,
      password,
      passwordConfirmation,
    }: {
      token: string;
      password: string;
      passwordConfirmation: string;
    }) => {
      return await boronClient.PATCH(
        "/api/v1/password_reset/{operator_reset_password_token}",
        {
          params: {
            path: {
              operator_reset_password_token: token,
            },
          },
          body: {
            operator: {
              password,
              password_confirmation: passwordConfirmation,
            },
          },
        },
      );
    },
    {
      onSuccess: () => {
        showSuccessMessage("パスワードを再設定しました");
        navigate("/login");
      },
      onError: () => {
        showErrorMessage("パスワードを再設定できませんでした");
      },
    },
  );

  const apiErrors =
    mutation.error && isUnprocessableEntityError(mutation.error)
      ? mutation.error.originalErrors
      : [];

  return {
    ...mutation,
    apiErrors,
  };
};
