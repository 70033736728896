import { useSearchParams } from "react-router-dom";
import { LearningMaterial } from "../../../features/StudentsLearningMaterialsNewPage";
import { useQueryError } from "../../../hooks/http/useQueryError.ts";
import { useOnMainScrollAreaScroll } from "../../../hooks/useMainScrollAreaScroll.ts";
import StudentInterface from "../../../interfaces/StudentInterface.ts";
import BackLink from "../../atoms/BackLink";
import Loader from "../../atoms/Loader";
import { SearchForm } from "./SearchForm.tsx";
import { SearchResult } from "./SearchResult.tsx";
import styles from "./styles.scss";
import {
  LearningMaterialSearchType,
  LearningMaterialSearchTypes,
  useFetchLearningMaterials,
} from "./useFetchLearningMaterials.ts";

type Props = {
  student: StudentInterface;
  isLoading: boolean;
  registeredLearningMaterialCodes: string[] | undefined;
  registeringLearningMaterialCodes: string[];
  onRegister: (studentId: string, materialCode: string) => void;
  isPreRegistration: boolean;
};

const StudentNewLearningMaterial: React.FC<Props> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleRegisterLearningMaterial =
    (learningMaterial: LearningMaterial) => () => {
      if (learningMaterial.isTypeDrill) {
        if (
          !window.confirm(
            `この教材が有償教材の場合、本棚登録をしたタイミングで利用料が発生します。詳しくは、デジタル教材に関する注意事項をご確認ください。\n※無償教材の場合、利用料は発生しません。\n\n「${props.student.fullName}」の本棚に「${learningMaterial.title}」を登録しますか？`,
          )
        ) {
          return;
        }
      }

      props.onRegister(props.student.id, learningMaterial.code);
    };

  const keyword = searchParams.get("keyword") || "";
  const type = coerceLearningMaterialSearchType(searchParams.get("type"));

  const handleSearch = (params: { keyword: string; type: string }) => {
    setSearchParams(params);
  };

  const {
    searchedLearningMaterials,
    totalCount,
    error,
    isLoading: isSearchingLearningMaterials,
    isFetchingNextPage,
    fetchNextPageIfPossible,
  } = useFetchLearningMaterials({
    sectionId: props.student.section.id,
    keyword,
    type: type,
  });
  useQueryError(error);

  useOnMainScrollAreaScroll(fetchNextPageIfPossible, [fetchNextPageIfPossible]);

  const indexPath = (): string => {
    const { student } = props;

    if (props.isPreRegistration) {
      return `/students/${student.id}/learning_materials/pre_registrations`;
    } else {
      return `/students/${student.id}/learning_materials`;
    }
  };

  return (
    <div className={styles.root}>
      <BackLink path={indexPath()}>戻る</BackLink>

      {/* 検索フォーム  */}
      <SearchForm
        defaultSearchKeyword={keyword}
        defaultSearchType={type}
        isSearching={isSearchingLearningMaterials}
        onSearch={handleSearch}
      />

      {/* 検索結果  */}
      <SearchResult
        student={props.student}
        handleRegisterLearningMaterial={handleRegisterLearningMaterial}
        learningMaterials={searchedLearningMaterials}
        totalCount={totalCount}
        registeredLearningMaterialCodes={props.registeredLearningMaterialCodes}
        registeringLearningMaterialCodes={
          props.registeringLearningMaterialCodes
        }
        searchType={type}
      />
      <Loader
        loading={
          props.isLoading || isSearchingLearningMaterials || isFetchingNextPage
        }
      />
    </div>
  );
};

const coerceLearningMaterialSearchType = (
  type: string | null,
): LearningMaterialSearchType => {
  if (isLearningMaterialSearchType(type)) {
    return type;
  } else {
    return "section_original";
  }
};

const isLearningMaterialSearchType = (
  type: string | null,
): type is LearningMaterialSearchType =>
  LearningMaterialSearchTypes.some((t) => t === type);

export default StudentNewLearningMaterial;
