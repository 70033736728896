import queryString from "query-string";
import * as React from "react";
import {
  LearningMaterialTag,
  toTagParams,
} from "../../../domains/LearningMaterialTag";
import { createContext } from "../../../helpers/React";
import { useEntityArray } from "../../../hooks/useEntityArray";
import { usePushHistory } from "../../../hooks/usePushHistory";
import { FilterParams } from "../SectionsSettingsManagementLearningMaterialsPage/useManagementLearningMaterialApi";

const { useContext, Provider } =
  createContext<ReturnType<typeof useLearningMaterialFilter>>();

export const useLearningMaterialFilterContext = useContext;

const useLearningMaterialFilter = () => {
  const selectedOptionsState = useEntityArray<LearningMaterialTag>();
  const [isTagLoaded, setIsTagLoaded] = React.useState(false);
  const [isInitialized, setIsInitialized] = React.useState(false);

  // URLパラメータからフィルターを復元する
  const updateFilterByQuery = (tags: LearningMaterialTag[]) => {
    const { learningMaterialTagIds, haveNoTag } = getFilterParamsByQuery();

    selectedOptionsState.replace(
      tags.filter((tag) => {
        return (
          learningMaterialTagIds?.includes(tag.id) ||
          (haveNoTag === "true" && tag.tagType === "have_no_tag")
        );
      }),
    );
  };

  const { pushHistory } = usePushHistory<Partial<FilterParams>>();
  // 指定されたタグで初期化する
  const initializeWithOptions = (items: LearningMaterialTag[]) => {
    if (isInitialized) return;

    const { learning_material_tag_ids, have_no_tag } = toTagParams({
      tags: items,
    });
    pushHistory({ learning_material_tag_ids, have_no_tag }, { replace: true });

    setIsInitialized(true);
  };

  // URLパラメータからフィルターを復元して初期化する
  const initializeWithQuery = (tags: LearningMaterialTag[]) => {
    if (isInitialized) return false;
    if (!isTagLoaded) return false;
    // フィルターに関するURLパラメータがない場合は何もしない
    const { learningMaterialTagIds, haveNoTag } = getFilterParamsByQuery();
    if (!learningMaterialTagIds && !haveNoTag) return false;

    updateFilterByQuery(tags);

    setIsInitialized(true);

    return true;
  };

  // フィルターに関するURLパラメータを取得する
  // NOTE: 生徒フィルターと共存させた時に、生徒フィルターに関するURLパラメータに影響されないようにしたい
  const getFilterParamsByQuery = () => {
    const queryParams = queryString.parse(location.search, {
      arrayFormat: "bracket",
    });
    const learningMaterialTagIds = queryParams.learning_material_tag_ids;
    const haveNoTag = queryParams.have_no_tag;

    return {
      learningMaterialTagIds,
      haveNoTag,
    };
  };

  return React.useMemo(
    () => ({
      ...selectedOptionsState,
      initializeWithOptions,
      initializeWithQuery,
      isInitialized,
      setIsInitialized,
      updateFilterByQuery,
      setIsTagLoaded,
      isTagLoaded,
    }),
    [
      selectedOptionsState.items,
      initializeWithOptions,
      initializeWithQuery,
      isInitialized,
      setIsInitialized,
      updateFilterByQuery,
      setIsTagLoaded,
      isTagLoaded,
    ],
  );
};

// 現状でdispatcherだけ必要なコンポーネントのユースケースがないのでProviderは分けない
export const LearningMaterialFilterProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  return <Provider value={useLearningMaterialFilter()}>{children}</Provider>;
};
