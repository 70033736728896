import { StylesConfig } from "react-select";
import styleVars from "../../../styles/variables.scss";
import { SelectWrapperProps } from "./index";

export type Size = "xs" | "sm" | "md";
export type FontSize = "sm" | "md";
export type SizeProps = {
  size?: Size;
  fontSize?: FontSize;
};

export type DropdownIndicatorProps = {
  hiddenDropdownIndicator?: boolean;
};

export type AlignItems = "top" | "center";
export type IndicatorAlignItemsProps = {
  indicatorAlignItems?: AlignItems;
};

export type OptionType = Record<string, unknown>;
// boron-web独自のスタイル
const placeholderStyles: StylesConfig<OptionType>["placeholder"] = (
  styles,
) => ({
  ...styles,
  color: styleVars.colorBlack,
  opacity: 0.5,
});

const singleValueStyles: StylesConfig<OptionType>["singleValue"] = (
  styles,
) => ({
  ...styles,
  color: styleVars.colorBlack,
  maxWidth: "calc(94% - 8px)",
});

const optionStyles: StylesConfig<OptionType>["option"] = (styles, state) => ({
  ...styles,
  color: state.isSelected ? "#fff" : styleVars.colorBlack,
  background: state.isSelected ? styleVars.colorPrimary : styles.background,
  "&:hover": {
    background: state.isSelected
      ? styleVars.colorPrimary
      : styleVars.colorGrayLighten2,
  },
});

const controlStyles: StylesConfig<OptionType>["control"] = (styles, state) => {
  const borderColor = (state.selectProps as SelectWrapperProps).hasError
    ? styleVars.colorRed
    : state.isFocused
      ? styleVars.colorPrimary
      : "#dde0e8";
  const size: Size | undefined = (state.selectProps as SizeProps).size;
  const fontSize: FontSize | undefined = (state.selectProps as SizeProps)
    .fontSize;

  return {
    display: "flex",
    height: sizeTable[size ?? "sm"],
    minHeight: sizeTable[size ?? "sm"],
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor,
    boxShadow: "none",
    boxSizing: "border-box",
    color: styleVars.colorsBlack,
    borderRadius: "5px",
    padding: (state.selectProps as DropdownIndicatorProps)
      .hiddenDropdownIndicator
      ? "0"
      : "0 0.8rem 0 0",
    fontSize: fontSizeTable[fontSize ?? "sm"],
    "&:hover": {
      borderColor,
      boxShadow: "none",
    },
    backgroundColor: state.isDisabled
      ? styleVars.colorGrayLighten3
      : (state.selectProps as SelectWrapperProps).hasError
        ? styleVars.colorRedLighten2
        : styles.backgroundColor,

    cursor: state.isDisabled ? "not-allowed" : "pointer",
  };
};

const valueContainerStyles: StylesConfig<OptionType>["valueContainer"] = (
  styles,
  state,
) => ({
  ...styles,
  opacity: state.selectProps.isDisabled ? "50%" : styles.opacity,
  pointerEvents: "auto",
});

const indicatorSeparatorStyles: StylesConfig<OptionType>["indicatorSeparator"] =
  (styles) => ({
    ...styles,
    display: "none",
  });

const dropdownIndicatorStyles: StylesConfig<OptionType>["dropdownIndicator"] = (
  styles,
  state,
) => ({
  ...styles,
  display: (state.selectProps as DropdownIndicatorProps).hiddenDropdownIndicator
    ? "none"
    : "",
});

const clearIndicatorStyles: StylesConfig<OptionType>["clearIndicator"] = (
  styles,
) => ({
  ...styles,
  cursor: "pointer",
});

const indicatorsContainerStyles: StylesConfig<OptionType>["indicatorsContainer"] =
  (styles, state) => ({
    ...styles,
    alignItems:
      (state.selectProps as IndicatorAlignItemsProps).indicatorAlignItems ??
      "center",
  });

const multiValueRemoveStyles: StylesConfig<OptionType>["multiValueRemove"] = (
  styles,
) => ({
  ...styles,
  cursor: "pointer",
});

const sizeTable = {
  xs: "3rem",
  sm: "4rem",
  md: "4.6rem",
};

const fontSizeTable = {
  sm: "1.3rem",
  md: "1.4rem",
};

export const customStyles: StylesConfig<OptionType> = {
  control: controlStyles,
  placeholder: placeholderStyles,
  singleValue: singleValueStyles,
  option: optionStyles,
  valueContainer: valueContainerStyles,
  indicatorSeparator: indicatorSeparatorStyles,
  dropdownIndicator: dropdownIndicatorStyles,
  clearIndicator: clearIndicatorStyles,
  indicatorsContainer: indicatorsContainerStyles,
  multiValueRemove: multiValueRemoveStyles,
};
