import queryString from "query-string";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { ActionTypes } from "../../../actions/common/session/types";
import { BoronNavigator } from "../../../navigators/BoronNavigator";
import Button from "../../atoms/Button/index";
import styles from "./styles.scss";

const Login = (): React.ReactElement => {
  const location = useLocation();
  const dispatch = useDispatch();
  const handleClick = () => {
    // reduxのstateのbackToにurlが残っているとログイン後にそちらにリダイレクトされてしまうためリセットする
    dispatch({ type: ActionTypes.RESET_BACK_TO_URL });

    const searchParams = location.state
      ? location.state.search
      : location.search;
    const backTo = queryString.parse(searchParams).url as string | null;
    if (backTo) {
      BoronNavigator.signInWithBackTo(backTo);
    } else {
      BoronNavigator.signIn();
    }
  };

  return (
    <div className={styles.root}>
      <Button className={styles.button} disabled={false} onClick={handleClick}>
        ログイン
      </Button>
    </div>
  );
};

export default Login;
