import classNames from "classnames";
import { ErrorMessage, useField } from "formik";
import { AdminSection } from "../../../interfaces/AdminSection";
import Checkbox from "../../atoms/Checkbox";
import Loader from "../../atoms/Loader";
import { MAX_COUNT_OF_SELECTABLE_SECTIONS } from "./useMultiSectionBatchesAdminSectionsSelectBox";

type Props = {
  isLoading: boolean;
};

export const MultiSectionBatchesAdminSectionsSelectBox = ({
  isLoading,
}: Props) => {
  const [field, _meta, helpers] = useField<AdminSection[]>("adminSections");
  const { value: adminSections } = field;
  const { setValue } = helpers;

  const hasReachedSectionsLimit =
    adminSections.filter((section) => section.checked).length <
    MAX_COUNT_OF_SELECTABLE_SECTIONS;

  return (
    <div className="flex flex-col gap-8 rounded-lg bg-gray-100 px-10 max-md:px-4 py-8">
      <div>
        <p>1. 出力したい校舎を選択してください</p>
        <p className="text-sm mt-1">
          ※最大{MAX_COUNT_OF_SELECTABLE_SECTIONS}校舎まで設定可能です
        </p>
      </div>
      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <div>
          {adminSections.length === 0 ? (
            <p className="text-red-400">管理者権限を持つ校舎がありません。</p>
          ) : (
            adminSections.map((section) => (
              <div key={section.id} className="flex">
                <span
                  onClick={() => {
                    if (
                      !hasReachedSectionsLimit &&
                      !adminSections.find(
                        (section) => section.id === section.id,
                      )?.checked
                    ) {
                      return;
                    }

                    setValue(
                      adminSections.map((adminSection) => {
                        return adminSection.id === section.id
                          ? { ...adminSection, checked: !adminSection.checked }
                          : adminSection;
                      }),
                    );
                  }}
                  className="flex items-center"
                >
                  <Checkbox
                    checked={section.checked}
                    disabled={!section.checked && !hasReachedSectionsLimit}
                  />
                  <label
                    className={classNames("inline-block", {
                      "text-gray-500":
                        !section.checked && !hasReachedSectionsLimit,
                      "cursor-pointer":
                        section.checked || hasReachedSectionsLimit,
                      "cursor-not-allowed":
                        !section.checked && !hasReachedSectionsLimit,
                    })}
                  >
                    {section.fullName}
                  </label>
                </span>
              </div>
            ))
          )}
        </div>
      )}
      <ErrorMessage
        name="adminSections"
        component="div"
        className="text-red-400"
      />
    </div>
  );
};
