import type { StudyRecordWithRestrictedAttributes } from "../../../../../interfaces/StudyRecordInterface";
import Amount from "../../../../atoms/Amount";
import Time from "../../../../atoms/Time";

type Props = {
  studyRecord: StudyRecordWithRestrictedAttributes;
};

export const Record = ({ studyRecord }: Props) => {
  if (!studyRecord.numberOfSeconds && !studyRecord.amount) {
    return null;
  }

  return (
    <div className="mt-6">
      {studyRecord.numberOfSeconds && studyRecord.numberOfSeconds > 0 ? (
        <div>
          <Time
            seconds={studyRecord.numberOfSeconds}
            size="small"
            dataTestId="timeline-item-time"
          />
        </div>
      ) : null}
      {studyRecord.amount && studyRecord.amount > 0 ? (
        <div className="mt-3">
          <Amount
            unit={studyRecord.unit}
            amount={studyRecord.amount}
            startPosition={studyRecord.startPosition}
            endPosition={studyRecord.endPosition}
            size="small"
            dataTestId="timeline-item-amount"
          />
        </div>
      ) : null}
    </div>
  );
};
