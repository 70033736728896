import { LearningMaterial as LearningMaterialType } from "../../../features/StudentsLearningMaterialsNewPage";
import { LearningMaterial } from "./LearningMaterial.tsx";

export const LearningMaterialList = ({
  learningMaterials,
  registeredLearningMaterialCodes,
  handleRegisterLearningMaterial,
  registeringLearningMaterialCodes,
}: {
  learningMaterials: LearningMaterialType[];
  registeredLearningMaterialCodes: string[];
  handleRegisterLearningMaterial: (
    learningMaterial: LearningMaterialType,
  ) => () => void;
  registeringLearningMaterialCodes: string[];
}) => {
  return (
    <ul>
      {learningMaterials.map((learningMaterial) => (
        <LearningMaterial
          key={learningMaterial.code}
          learningMaterial={learningMaterial}
          registeredLearningMaterialCodes={registeredLearningMaterialCodes}
          handleRegisterLearningMaterial={handleRegisterLearningMaterial}
          disabled={registeringLearningMaterialCodes.includes(
            learningMaterial.code,
          )}
        />
      ))}
    </ul>
  );
};
