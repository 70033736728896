import { Link } from "react-router-dom";
import TimeHelper from "../../../helpers/TimeHelper";
import SectionInterface from "../../../interfaces/SectionInterface";
import { StaffBoardPost } from "../../../interfaces/StaffBoardPostInterface";
import Icon from "../../atoms/Icon/index";
import Loader from "../../atoms/Loader/index";
import styles from "./styles.scss";

interface Props {
  section: SectionInterface;
  loading: boolean;
  staffBoardPosts: StaffBoardPost[] | undefined;
}

const SectionDashboardStaffBoard = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.rowBetween}>
        <h2 className={styles.heading}>スタッフボード</h2>
        <Link
          to={`/sections/${props.section.id}/staff_board_posts`}
          className={styles.navi}
        >
          一覧
          <Icon name="icon-arrow-right" />
        </Link>
      </div>
      <div>{renderPostLinks(props)}</div>
    </div>
  );
};

const renderPostLinks = (props: Props) => {
  const { section, staffBoardPosts, loading } = props;

  if (loading) {
    return <Loader loading={loading} />;
  }

  if (!staffBoardPosts) {
    return null;
  }

  if (staffBoardPosts.length > 0) {
    return staffBoardPosts.map((post) => (
      <div
        className={styles.row}
        key={`SectionDashboardStaffBoard-PostLinks-${post.id}`}
      >
        <time className={styles.date}>
          {TimeHelper.japaneseFormatFullDateTime(post.postedAt)}
        </time>
        <Link
          to={`/sections/${section.id}/staff_board_posts/${post.id}`}
          className={styles.link}
        >
          {post.title}
        </Link>
      </div>
    ));
  } else {
    return <p className={styles.notfound}>こちらに最新の投稿5件を表示します</p>;
  }
};

export default SectionDashboardStaffBoard;
