import { IconStar } from "@studyplus/boron-ui";
import { startOfToday } from "date-fns";
import Loader from "../../components/atoms/Loader";
import { useSingleDateFilterContext } from "../../contexts/SingleDateFilterContext";
import { useQueryError } from "../../hooks/http/useQueryError";
import { useErrorDispatcher } from "../../hooks/useErrorDispatcher";
import { useFetchKoekakeAssists } from "./useFetchKoekakeAssists";

type Props = {
  studentId: string;
};

export const KoekakeAssists = ({ studentId }: Props) => {
  const { date } = useSingleDateFilterContext();
  const datetime = date?.toISOString() ?? startOfToday().toISOString(); // 今日の始まりの日付を設定する

  const { messages, isLoading, error } = useFetchKoekakeAssists({
    studentId,
    datetime,
  });

  useQueryError(error);
  const { sendNotFound } = useErrorDispatcher();
  if (error) {
    sendNotFound();
    return null;
  }

  if (isLoading || messages === undefined) {
    return <Loader loading={isLoading} />;
  }

  return (
    <div className="flex flex-col gap-2 py-4 pl-6">
      {messages.map((message) => (
        <div
          key={message}
          className="flex w-fit items-center rounded-full bg-blue-100 pl-3"
        >
          <span className="m-1">
            <IconStar className="text-blue-400" />
          </span>
          <p className="py-1 pl-2 pr-4 text-sm font-bold text-blue-400">
            {message}
          </p>
        </div>
      ))}
    </div>
  );
};
