import { Button } from "@studyplus/boron-ui";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router";
import { isUnprocessableEntityError } from "../../../errors";
import { HTTPErrors } from "../../../errors";
import { useUpdateStaffBoardPost } from "../../../features/SectionsStaffBoardPostEditPage/SectionStaffBoardPostEdit/useUpdateStaffBoardPost";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { StaffBoardPost } from "../../../interfaces/StaffBoardPostInterface";
import { BlockRow } from "../../atoms/BlockRow";
import ErrorText from "../../atoms/ErrorText";
import Input from "../../atoms/Input";
import Label from "../../atoms/Label";
import { MainSection } from "../../atoms/MainSection";
import Textarea from "../../atoms/Textarea";
import ApiErrorMessages from "../StaffBoardPost/ApiErrorMessages";
import { ApiError } from "../StaffBoardPost/ApiErrorMessages";
import { InputStaffBoardAttachments } from "./InputStaffBoardAttachments";
import { StaffBoardAttachmentFiles } from "./StaffBoardAttachmentFiles";
import { StaffBoardAttachmentImages } from "./StaffBoardAttachmentImages";
import {
  FormValues,
  useStaffBoardPostEditForm,
} from "./useStaffBoardPostEditForm";

interface Props {
  sectionId: string;
  staffBoardPost: StaffBoardPost;
  operatorName: string;
}

export const StaffBoardPostEditForm = ({
  sectionId,
  staffBoardPost,
  operatorName,
}: Props) => {
  const navigate = useNavigate();
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const [apiError, setApiError] = useState<ApiError>(null);

  const { mutate, isPending } = useUpdateStaffBoardPost({
    sectionId,
    staffBoardPostId: staffBoardPost?.id ?? "",
    onSuccess: () => {
      showSuccessMessage("スタッフボードを更新しました");
      navigate(`/sections/${sectionId}/staff_board_posts`);
    },
    onError: (error: HTTPErrors) => {
      if (isUnprocessableEntityError(error)) {
        setApiError({
          message: "ValidationError",
          errors: error.originalErrors
            .filter((err) => err.field && err.message)
            .map((err) => ({
              field: err.field,
              message: err.message,
            })),
        });
      }
      showErrorMessage("スタッフボードを更新できませんでした");
    },
  });

  const {
    staffBoardPostAttachmentImages,
    staffBoardPostAttachmentFiles,
    initialValues,
    staffBoardPostEditFormSchema,
    convertFormValuesToRequestValues,
    setHiddenAttachmentIds,
  } = useStaffBoardPostEditForm({
    staffBoardPost,
    operatorName,
  });

  return (
    <BlockRow marginTop="1.6rem">
      <MainSection>
        <Formik<FormValues>
          initialValues={initialValues}
          initialErrors={undefined}
          validationSchema={staffBoardPostEditFormSchema}
          onSubmit={(values, { resetForm }) => {
            const requestValues = convertFormValuesToRequestValues(values);
            mutate(requestValues);
            resetForm();
          }}
        >
          {({ isValid }) => (
            <Form aria-label="スタッフボード投稿フォーム">
              <div className="flex flex-row gap-4 mb-4">
                <div className="flex-1">
                  <Label htmlFor="staff_board_post__title">タイトル</Label>
                  <Field
                    id="staff_board_post__title"
                    name="title"
                    component={Input}
                    placeholder="タイトルを入力"
                  />
                  <ErrorMessage
                    name="title"
                    component={ErrorText}
                    className="text-red-500 mt-1"
                  />
                </div>
                <div className="flex-1">
                  <Label htmlFor="staff_board_post__name">スタッフ名</Label>
                  <Field
                    id="staff_board_post__name"
                    name="name"
                    component={Input}
                    placeholder="スタッフ名を入力"
                  />
                  <ErrorMessage
                    name="name"
                    component={ErrorText}
                    className="text-red-500 mt-1"
                  />
                </div>
              </div>
              <Label htmlFor="staff_board_post__comment">コメント</Label>
              <Field
                id="staff_board_post__comment"
                name="comment"
                component={Textarea}
                rows={4}
              />
              <ErrorMessage
                name="comment"
                component={ErrorText}
                className="text-red-500 mt-1"
              />
              <ApiErrorMessages apiError={apiError} />
              <StaffBoardAttachmentImages
                staffBoardPostAttachmentImages={staffBoardPostAttachmentImages}
                setHiddenAttachmentIds={setHiddenAttachmentIds}
              />
              <StaffBoardAttachmentFiles
                staffBoardPostAttachmentFiles={staffBoardPostAttachmentFiles}
                setHiddenAttachmentIds={setHiddenAttachmentIds}
              />
              <div className="flex justify-end items-center mt-4 gap-10">
                <InputStaffBoardAttachments />
                <Button
                  type="submit"
                  disabled={!isValid || isPending}
                  isLoading={isPending}
                >
                  更新
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </MainSection>
    </BlockRow>
  );
};
