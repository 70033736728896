import { IconUploadFile } from "@studyplus/boron-ui";
import FileHelper from "../../../helpers/FileHelper";
import InputIcon from "../../atoms/InputIcon";
import TagButton from "../TagButton";

const acceptFileExtensions = [
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".csv",
  ".pdf",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".ppt",
  ".pptx",
];

export const InputFileList = ({
  inputFiles,
  addInputFile,
  removeInputFile,
}: {
  inputFiles: File[];
  addInputFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  removeInputFile: (index: number) => void;
}) => {
  if (!inputFiles) return null;

  return (
    <>
      <div className="grow">
        {inputFiles.map((inputFile: File, i: number) => {
          return (
            <TagButton
              key={`StaffBoardPost-attachmentNames-${i}`}
              label={FileHelper.truncateFilename(inputFile.name, 30)}
              variant="outline"
              iconName="icon-close-x"
              iconType="circle"
              colorType="primary"
              hoverColorType="light-blue"
              onClick={() => removeInputFile(i)}
              className="mb-4 mr-2"
            />
          );
        })}
      </div>
      <InputIcon
        icon={
          <IconUploadFile className="text-gray-800 hover:bg-[#eee] p-1 rounded hover:text-blue-400" />
        }
        inputAttributes={{
          id: "staff_board_post_attachments_attributes",
          type: "file",
          accept: acceptFileExtensions.join(","),
        }}
        tooltipLabel="ファイルの追加( jpg,png,gif,csv,pdf,word,excel,ppt )"
        onChange={addInputFile}
      />
    </>
  );
};
