import { Component, ComponentClass, FC } from "react";
import { HandleThunkActionCreator, connect } from "react-redux";

import queryString from "query-string";
import { loadSection } from "../actions/common/currentSection";
import { dispatchResetPageErrors } from "../actions/common/errors";
import { initializeSectionTags } from "../actions/common/initializeSectionTags";
import {
  checkPrivileges,
  loadOperatorPrivileges,
} from "../actions/common/operatorPrivileges";
import { resetApp } from "../actions/common/resetApp";
import { loadStudent } from "../actions/common/student";
import {
  showErrorFlashMessage,
  showSuccessFlashMessage,
} from "../actions/flashMessage";
import { useStudentFilterContext } from "../components/features/NewStudentFilter/useStudentFilterContext";
import { StudentTagFilterInterface } from "../domains/StudentTag";
import FiltersHelper from "../helpers/FiltersHelper";
import { WithRouterProps, withRouter } from "../helpers/RouterHelper";
import AppStateInterface from "../interfaces/AppStateInterface";
import DateRangeFilterInterface from "../interfaces/DateRangeFilterInterface";
import ErrorsStateInterface from "../interfaces/ErrorsStateInterface";
import FiltersInterface, {
  FiltersQueryInterface,
  OrderDirFilterType,
} from "../interfaces/FiltersInterface";
import OperatorInterface, {
  OperatorPrivilege,
} from "../interfaces/OperatorInterface";
import OperatorPrivilegesStateInterface from "../interfaces/OperatorPrivilegesStateInterface";
import { SectionStateInterface } from "../interfaces/SectionInterface";
import StudentInterface, {
  StudentStateInterface,
} from "../interfaces/StudentInterface";

export interface AuthenticatedPageCallbacks {
  onDateRangeFilterChange: (dateRangeFilter: DateRangeFilterInterface) => void;
  onDateFilterChange: (dateFilter: Date) => void;
  onOrderChange: (order: string, orderDir: OrderDirFilterType) => void;
  onStudentFilterChange: (tagFilter: StudentTagFilterInterface) => void;
  onSearchStudent: (studentNameFilters: {
    firstName: string;
    lastName: string;
  }) => void;
}

export interface DispatchProps {
  resetApp: HandleThunkActionCreator<typeof resetApp>;
  loadSection: HandleThunkActionCreator<typeof loadSection>;
  loadStudent: HandleThunkActionCreator<typeof loadStudent>;
  loadOperatorPrivileges: HandleThunkActionCreator<
    typeof loadOperatorPrivileges
  >;
  dispatchResetPageErrors: HandleThunkActionCreator<
    typeof dispatchResetPageErrors
  >;
  initializeSectionTags: HandleThunkActionCreator<typeof initializeSectionTags>;
  checkPrivileges: HandleThunkActionCreator<typeof checkPrivileges>;
  showErrorFlashMessage: HandleThunkActionCreator<typeof showErrorFlashMessage>;
  showSuccessFlashMessage: HandleThunkActionCreator<
    typeof showSuccessFlashMessage
  >;
}

export interface StateProps {
  operators: OperatorInterface[];
  currentOperator: OperatorInterface | null;
  currentSection: SectionStateInterface;
  student: StudentStateInterface;
  navigationOpened: boolean;
  filters: FiltersInterface[];
  errors: ErrorsStateInterface;
  operatorPrivileges: OperatorPrivilegesStateInterface;
  isLoggedIn: boolean;
}

export type AuthenticateRouterProps = { studentId: string; sectionId: string };

export interface AuthenticatedPageProps
  extends DispatchProps,
    StateProps,
    WithRouterProps<AuthenticateRouterProps> {
  studentFilterContext: ReturnType<typeof useStudentFilterContext>;
}

const mapStateToProps = (state: AppStateInterface) => {
  return {
    operators: state.session.loggedInOperators,
    currentOperator: state.session.currentOperator,
    navigationOpened: state.navigationOpened,
    filters: state.filters,
    errors: state.errors,
    currentSection: state.currentSection,
    student: state.student,
    operatorPrivileges: state.operatorPrivileges,
    isLoggedIn: state.session.isLoggedIn,
  };
};

const actions = {
  resetApp,
  dispatchResetPageErrors,
  loadSection,
  loadStudent,
  loadOperatorPrivileges,
  initializeSectionTags,
  checkPrivileges,
  showErrorFlashMessage,
  showSuccessFlashMessage,
};

const enhanceAuthenticatedPage = (
  WrappedComponent: ComponentClass<any, any> | FC<any>,
  requiredPrivileges?: OperatorPrivilege[],
) => {
  class AuthenticatedPage extends Component<AuthenticatedPageProps> {
    get sectionId(): string | null {
      if (this.props.currentSection.data) {
        return this.props.currentSection.data.id;
      } else if (this.props.student.data) {
        return this.props.student.data.section.id;
      } else {
        return null;
      }
    }

    get studentFilter(): StudentTagFilterInterface {
      return this.props.studentFilterContext.findTagFilter({
        sectionId: this.sectionId || "",
      });
    }

    get filter(): FiltersInterface | undefined {
      return this.props.filters.find(
        (filter: FiltersInterface) => filter.sectionId === this.sectionId,
      );
    }

    constructor(
      props: AuthenticatedPageProps & WithRouterProps<AuthenticateRouterProps>,
    ) {
      super(props);

      if (this.hasError()) {
        return;
      }

      if (!this.props.isLoggedIn || this.props.currentOperator) {
        // **校舎一覧画面以外**ではこの関数が実行されたとき
        // - 校舎情報 currentSection.data
        // - タグ studentFilters.tagFilter.tags
        // - 権限の情報 operatorPrivileges.data
        // が必ず取得される
        this.loadMainResource();
      }
    }

    componentDidMount() {
      this.checkPrivileges();
    }

    componentDidUpdate(): void {
      if (this.props.student.data && this.props.params.studentId) {
        if (this.props.params.studentId !== this.props.student.data.id) {
          this.loadStudent(this.props.params.studentId);
        }
      }
    }

    render() {
      // 認証されていない状態
      if (!this.props.currentOperator) {
        return null;
      }

      return (
        <WrappedComponent
          {...this.props}
          onDateRangeFilterChange={this.handleDateRangeFilterChange}
          onDateFilterChange={this.handleDateFilterChange}
          onOrderChange={this.handleOrderChange}
          onSearchStudent={this.handleSearchStudent}
        />
      );
    }

    private handleDateRangeFilterChange = (
      dateRangeFilter: DateRangeFilterInterface,
    ) => {
      this.pushFilterHistory({ dateRangeFilter });
    };

    private handleDateFilterChange = (dateFilter: Date) => {
      this.pushFilterHistory({ dateFilter });
    };

    private handleOrderChange = (
      order: string,
      orderDir: OrderDirFilterType,
    ) => {
      this.pushFilterHistory({ orderDir, order });
    };

    private handleSearchStudent = (studentNameFilters: {
      firstName: string;
      lastName: string;
    }) => {
      const { firstName, lastName } = studentNameFilters;

      if (!this.sectionId) {
        return;
      }

      const query = queryString.stringify(
        {
          first_name: firstName,
          last_name: lastName,
          ...this.props.studentFilterContext.getStudentFilterParams(),
        },
        {
          arrayFormat: "bracket",
          skipEmptyString: true,
        },
      );

      this.props.navigate({
        pathname: `/sections/${this.sectionId}/settings/students`,
        search: query,
      });
    };

    private pushFilterHistory(obj: FiltersQueryInterface) {
      let filtersQuery: FiltersQueryInterface = {};

      if (this.filter) {
        filtersQuery = { ...filtersQuery, ...this.filter };
      }

      filtersQuery = {
        ...filtersQuery,
        ...obj,
      };

      const query = FiltersHelper.toQueryString(filtersQuery);

      this.props.navigate({ search: query });
    }

    // 校舎ページだったら校舎、生徒ページだったら生徒を読み込み
    private loadMainResource() {
      const { studentId, sectionId } = this.props.params;
      studentId && this.loadStudent(studentId);
      sectionId && this.loadSection(sectionId);
    }

    // 校舎読み込み
    private loadSection(sectionId: string) {
      const { props } = this;

      // currentSectionが存在しないとき or currentSectionのidとパスパラメータのsectionIdが異なるとき
      if (
        !props.currentSection.data ||
        sectionId !== props.currentSection.data.id
      ) {
        // redux storeを初期化
        props.resetApp();
        // 校舎の読込
        props.loadSection(sectionId);
        // 権限の読込
        props.loadOperatorPrivileges(sectionId, requiredPrivileges);
        // 生徒タグ読み込み
        props.studentFilterContext.setFilterKey(sectionId);
        props.initializeSectionTags(sectionId, props.studentFilterContext);
      } else if (!props.operatorPrivileges.data) {
        // アカウント切り替えでprivilegesがリセットされたときの権限チェック
        props.loadOperatorPrivileges(sectionId, requiredPrivileges);
      } else {
        // 通常時の権限チェック
        requiredPrivileges &&
          props.checkPrivileges(
            props.operatorPrivileges.data.privileges,
            requiredPrivileges,
          );
      }
    }

    // 生徒読み込み
    private loadStudent(studentId: string) {
      const { props } = this;

      // studentが存在しないとき or studentのidとパスパラメータのstudentIdがことなるとき
      if (!props.student.data || studentId !== props.student.data.id) {
        // redux storeを初期化
        props.resetApp();
        // 生徒，校舎，権限の読込
        props
          .loadStudent(studentId)
          .then((student: StudentInterface | void) => {
            if (student) {
              const sectionId = student.section.id;
              // 校舎の読込
              props.loadSection(sectionId);
              // 権限の読込
              props.loadOperatorPrivileges(sectionId);
              // 生徒タグ読み込み
              props.studentFilterContext.setFilterKey(sectionId);
              props.initializeSectionTags(
                sectionId,
                props.studentFilterContext,
              );
            }
          });
      }
    }

    private checkPrivileges() {
      // 画面遷移のときの権限チェック
      if (this.props.operatorPrivileges.data && requiredPrivileges) {
        this.props.checkPrivileges(
          this.props.operatorPrivileges.data.privileges,
          requiredPrivileges,
        );
      }
    }

    private hasError(): boolean {
      return Object.keys(this.props.errors).some((key: string) => {
        return (this.props.errors as any)[key];
      });
    }
  }

  return withStudentFilterContext(
    withRouter<AuthenticatedPageProps>(
      connect(mapStateToProps, actions)(AuthenticatedPage),
    ),
  );
};

// 生徒フィルターのReact Contextを流すためのwrapper
// enhanceAuthenticatedPageでラップされるページがクラスコンポーネントの場合にuseStudentFilterContextを呼び出せないためpropsとして流し込む
const withStudentFilterContext = (Component: any) => {
  return (props: any) => {
    const studentFilterContext = useStudentFilterContext();
    return <Component {...props} studentFilterContext={studentFilterContext} />;
  };
};

export default enhanceAuthenticatedPage;
