import "./polyfills";
// CSSの優先度が変わってしまうので、必ずRoutesより前でimportする
import "@studyplus/boron-ui/dist/boron-ui.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import type {} from "redux-thunk/extend-redux";
import Routes from "./containers/Routes";
import "./styles/global.scss";
import "./styles/tailwind.css";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ReactGA4 from "react-ga4";
import { Helmet } from "react-helmet";
import Settings from "./helpers/Settings";
import store from "./store";
import { persistor } from "./store";

declare global {
  /**
   * NOTE: windowオブジェクトの拡張。
   * どうしても必要なものはここへ。極力windowへのアサインは避けてください
   */
  interface Window {
    // windowがスクロール領域ではないため、MainLayoutのonScrollコールバックを
    // 外のコンポーネントから渡すため、windowに生やしています
    onMainScrollAreaScroll?: () => void;

    // 環境変数に依存した設定値
    // 設定値は別のビルドで生成したJS(src/environment.js)からwindowに生やしているため、
    // そこを参照できるようにしています
    Environment: { [key: string]: any };
  }
}

const gaUrl = "https://www.google-analytics.com/g/collect";
if (Settings.SENTRY_DSN) {
  Sentry.init({
    dsn: Settings.SENTRY_DSN,
    environment: Settings.ENVIRONMENT,
    release: Settings.RELEASE,
    ignoreErrors: [
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
      "Can't find variable: IntersectionObserver",
      "Can't find variable: ResizeObserver",
      "Can't find variable: gmo",
      "Non-Error promise rejection captured with keys",

      // google translate extension由来と思われるエラーを回避
      // 部分一致なのでevaluating 'a.fa'やevaluating 'a.h'などこれまで出たことのあるエラーを回避することを期待している
      "undefined is not an object (evaluating 'a.",
      // 特定のユーザーでのみ起きているエラーを無視
      // ユーザーに確認したところ画面の利用には影響ないそうでした
      // また、特別な拡張などは入れていないとのことでしたが、
      // boron-webからfacebookのAPIを叩いたりなどもしていないので、ユーザー環境固有の問題だと判断して無視しています
      // ref: https://studyplus.slack.com/archives/CNXH12CVD/p1689729571938059
      "Cannot read properties of undefined (reading 'facebook.json')",
    ],
    allowUrls: [Settings.ROOT_URL],
    beforeSend(event) {
      const exception = event.exception;

      if (exception && exception.values) {
        // NOTE: boron-studentと同じく、google-analyticsへのリクエストのエラーを無視する
        // TypeError: Load failedのエラーか確認する
        if (
          exception.values[0].type === "TypeError" &&
          exception.values[0].value === "Load failed"
        ) {
          // google-analyticsにリクエストされているか確認
          const breadcrumbs = event.breadcrumbs;
          if (breadcrumbs) {
            const hasGoogleAnalyticsError = breadcrumbs.some(
              (breadcrumb) =>
                breadcrumb.type === "http" &&
                breadcrumb.level === "error" &&
                breadcrumb.data?.url.startsWith(gaUrl),
            );

            if (hasGoogleAnalyticsError) {
              return null;
            }
          }
        }
      }
      return event;
    },
  });
}

if (Settings.GOOGLE_ANALYTICS_MESUREMENT_ID) {
  ReactGA4.initialize(Settings.GOOGLE_ANALYTICS_MESUREMENT_ID);
}

// CSSからwindow高さを参照するためにdocumentからCSS変数へセットしている
// ref: https://coliss.com/articles/build-websites/operation/css/viewport-units-on-mobile.html
document.documentElement.style.setProperty(
  "--innerHeight",
  `${window.innerHeight}px`,
);

const queryClient = new QueryClient();

// 開発時、MSW_BROWSERをenableにするとモックサーバーを有効にできます
// デザインレビューでも使えるようにcage環境でも有効化できるようにしています
// モックの中身はmock/browser.tsにあります
if (
  (Settings.ENVIRONMENT === "local" || Settings.ENVIRONMENT === "cage") &&
  Settings.MSW_BROWSER === "enable"
) {
  const { worker } = require("../mock/browser");
  worker.start();
}

const container = document.getElementById("root");
if (container !== null) {
  const root = createRoot(container);
  root.render(
    <>
      <Helmet>
        {Settings.ENVIRONMENT !== "prod" && (
          <meta name="robots" content="noindex"></meta>
        )}
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes store={store} persistor={persistor} />
        </Router>
        {window.Environment.MODE === "development" ? (
          <ReactQueryDevtools initialIsOpen={false} />
        ) : null}
      </QueryClientProvider>
    </>,
  );
}
