import StudentInterface from "../../../../../interfaces/StudentInterface";
import type { StudyRecordWithRestrictedAttributes } from "../../../../../interfaces/StudyRecordInterface";
import Icon from "../../../../atoms/Icon";
import LinkButton from "../../../../atoms/LinkButton";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../../../../general/Popover/Popover";

interface Props {
  studyRecord: StudyRecordWithRestrictedAttributes;
  student: StudentInterface;
  comment: string;
  setComment: (comment: string) => void;
  setIsVisible: (isVisible: boolean) => void;
}

const cautionMessageOfForm = "コメント欄を上書きしてもよろしいですか？";
const templateComment =
  "お疲れ様でした、つまづいたところはありませんでしたか？";

const handleClick = ({
  comment,
  setComment,
  setIsVisible,
}: Pick<Props, "comment" | "setComment" | "setIsVisible">) => {
  const shouldSetComment =
    comment.trim() === "" || window.confirm(cautionMessageOfForm);
  if (shouldSetComment) {
    setIsVisible(true);
    setComment(templateComment);
  }
};

export const TimelineInsertTemplateCommentButton = ({
  studyRecord,
  comment,
  setComment,
  setIsVisible,
}: Props) => {
  const isRestricted = studyRecord.restrictedAttributes.includes("comments");

  if (isRestricted) {
    return (
      <PopoverProvider>
        <Popover>
          <PopoverTrigger asChild>
            <LinkButton type="button">
              <div className="inline-flex cursor-not-allowed items-center opacity-50">
                <Icon name="icon-action-template" />
                <span className="align-middle">質問ありますか？</span>
              </div>
            </LinkButton>
          </PopoverTrigger>
          <PopoverPortal>
            <PopoverContent>
              <div className="text-center">
                プロプランにすると学習記録に
                <br />
                「コメント」ができます。
              </div>
            </PopoverContent>
          </PopoverPortal>
        </Popover>
      </PopoverProvider>
    );
  } else {
    return (
      <LinkButton
        onClick={() => handleClick({ comment, setComment, setIsVisible })}
      >
        <Icon
          name="icon-action-template"
          className="mr-[0.2rem] align-middle"
        />
        <span className="align-middle">質問ありますか？</span>
      </LinkButton>
    );
  }
};
