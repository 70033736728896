import Loader from "../../components/atoms/Loader";
import TimelineItem from "../../components/features/Timeline/TimelineItem";
import OperatorInterface from "../../interfaces/OperatorInterface";
import StudentInterface from "../../interfaces/StudentInterface";
import styles from "./StudentTimeline.scss";
import {
  StudentTimelineResponse,
  useFetchStudentTimeline,
} from "./useFetchStudentTimeline";

interface Props {
  currentOperator: OperatorInterface;
  student: StudentInterface;
  timeline: StudentTimelineResponse["studyRecords"]["data"];
  loading: boolean;
  updateStudyRecordQueryCache: ReturnType<
    typeof useFetchStudentTimeline
  >["updateStudyRecordQueryCache"];
}

const StudentTimeline = ({
  currentOperator,
  student,
  timeline,
  loading,
  updateStudyRecordQueryCache,
}: Props) => {
  if (loading && !timeline) {
    return <Loader loading={loading} />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.timeline}>
        {timeline && timeline.length > 0 ? (
          <StudentTimelineItems
            currentOperator={currentOperator}
            student={student}
            timeline={timeline}
            updateStudyRecordQueryCache={updateStudyRecordQueryCache}
          />
        ) : (
          <p>勉強記録がまだありません</p>
        )}
      </div>
      {loading && <Loader loading={loading} />}
    </div>
  );
};

const StudentTimelineItems = ({
  currentOperator,
  student,
  timeline,
  updateStudyRecordQueryCache,
}: Pick<
  Props,
  "currentOperator" | "student" | "timeline" | "updateStudyRecordQueryCache"
>) => {
  return (
    <>
      {timeline.map((studyRecord) => {
        return (
          <div
            className={styles.timelineItem}
            key={`study-record-${studyRecord.id}`}
          >
            <TimelineItem
              currentOperator={currentOperator}
              studyRecord={studyRecord}
              student={student}
              sectionId={studyRecord.student.section.id}
              showHeader={true}
              canSendComment={studyRecord.student.canSendComment}
              updateStudyRecordQueryCache={updateStudyRecordQueryCache}
              key={`StudentTimeline-TimelineItem-${studyRecord.id}`}
            />
          </div>
        );
      })}
    </>
  );
};

export default StudentTimeline;
