import Loader from "../../components/atoms/Loader";
import SubLayout from "../../components/atoms/SubLayout";
import NotFoundErrorPage from "../../containers/pages/NotFoundErrorPage";
import { WithRouterProps, withRouter } from "../../helpers/RouterHelper";
import PasswordResetForm from "./PasswordResetForm";
import { useResetPassword } from "./useResetPassword";
import { useVerifyResetPasswordToken } from "./useVerifyResetPasswordToken";

type Props = WithRouterProps<{ password_reset_token: string }>;

const Index = (props: Props) => {
  const { isPending: isVerifying, error: verifyError } =
    useVerifyResetPasswordToken({
      resetPasswordToken: props.params.password_reset_token,
    });

  const {
    mutate: resetPassword,
    isPending: isResetting,
    apiErrors,
  } = useResetPassword();

  if (verifyError) return <NotFoundErrorPage />;

  const resetPasswordAction = (
    password: string,
    passwordConfirmation: string,
  ) => {
    if (verifyError) return;

    resetPassword({
      token: props.params.password_reset_token,
      password,
      passwordConfirmation,
    });
  };

  const isLoading = isVerifying || isResetting;

  return (
    <SubLayout>
      <SubLayout.Header />
      <SubLayout.Main>
        {!isVerifying ? (
          <div>
            <SubLayout.Heading>パスワード再設定</SubLayout.Heading>
            <PasswordResetForm
              resetPassword={resetPasswordAction}
              apiErrors={apiErrors}
              submitting={isResetting}
            />
          </div>
        ) : (
          <Loader loading={isLoading} />
        )}
      </SubLayout.Main>
      <SubLayout.Footer />
    </SubLayout>
  );
};

export default withRouter<Props>(Index);
