import { StaffBoardPost } from "../../../interfaces/StaffBoardPostInterface";
import Icon from "../../atoms/Icon";
import SquareImageList from "../../atoms/SquareImageList";
import { isImageAttachment } from "../StaffBoardPost/isImageAttachment";

type Props = {
  attachments: StaffBoardPost["attachments"];
};

type Attachment = StaffBoardPost["attachments"][number];

export const StaffBoardPostAttachments = ({ attachments }: Props) => {
  const { images, files } = Object.groupBy(attachments, (attachment) =>
    isImageAttachment(attachment) ? "images" : "files",
  );

  return (
    <div className="flex flex-col gap-7">
      {images !== undefined && <StaffBoardPostImages images={images} />}
      {files !== undefined && <StaffBoardPostFiles files={files} />}
    </div>
  );
};

const StaffBoardPostImages = ({ images }: { images: Attachment[] }) => {
  return (
    <SquareImageList
      imageUrls={images.map((image) => image.url)}
      keys={images.map((image) => image.id)}
    />
  );
};

const StaffBoardPostFiles = ({ files }: { files: Attachment[] }) => {
  return (
    <div className="flex flex-col gap-7">
      {files.map((file) => (
        <div key={file.id}>
          <a
            href={file.url}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center gap-5 w-fit"
          >
            <Icon name="icon-file" className="fill-blue-400" />
            <span className="text-blue-400">{file.filename}</span>
          </a>
        </div>
      ))}
    </div>
  );
};
