import { useInfiniteQuery } from "@tanstack/react-query";
import { boronClient } from "../../../api.ts";
import { createError } from "../../../errors.ts";
import { getNextPageParam } from "../../../helpers/ReactQueryHelper.ts";

export const LearningMaterialSearchTypes = [
  "section_original",
  "drill",
  "general_books",
  "itunes_app",
] as const;
export type LearningMaterialSearchType =
  (typeof LearningMaterialSearchTypes)[number];

export const useFetchLearningMaterials = ({
  sectionId,
  keyword,
  type,
}: {
  sectionId: string;
  keyword: string;
  type: LearningMaterialSearchType;
}) => {
  const result = useInfiniteQuery({
    queryKey: [
      "/api/v1/sections/{section_id}/learning_materials/search",
      sectionId,
      { keyword, type },
    ],
    queryFn: async ({ pageParam }) => {
      const { response, data } = await boronClient.GET(
        "/api/v1/sections/{section_id}/learning_materials/search",
        {
          params: {
            path: {
              section_id: sectionId,
            },
            query: {
              page: pageParam,
              keyword: keyword,
              type: type,
            },
          },
        },
      );
      if (response.ok && data) {
        return data.learningMaterials;
      }
      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
    refetchOnWindowFocus: false,
    enabled: !!keyword && !!type,
  });

  const searchedLearningMaterials = result.data?.pages
    ?.map((page) => page.data)
    .flat();
  const totalCount = result.data?.pages?.[0]?.meta.totalCount ?? 0;

  const fetchNextPageIfPossible = () => {
    if (!result.hasNextPage || result.isFetchingNextPage) {
      return;
    }

    result.fetchNextPage();
  };

  return {
    ...result,
    searchedLearningMaterials,
    totalCount,
    fetchNextPageIfPossible,
  };
};
