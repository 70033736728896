import { useQuery } from "@tanstack/react-query";
import { boronClient } from "../../api.ts";
import { createError } from "../../errors.ts";

export const useFetchAllPreRegisteredLearningMaterials = ({
  studentId,
}: {
  studentId: string;
}) =>
  useQuery({
    queryKey: [
      "/api/v1/students/{student_id}/learning_materials/pre_registrations",
      studentId,
    ],
    queryFn: async () => {
      const { response, data } = await boronClient.GET(
        "/api/v1/students/{student_id}/learning_materials/pre_registrations",
        {
          params: {
            path: {
              student_id: studentId,
            },
          },
        },
      );
      if (response.ok && data) {
        return data.learningMaterials;
      }
      throw await createError(response);
    },
  });
