import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { boronClient } from "../../api";
import { HTTPErrors, createError } from "../../errors";
import { StudyRecordWithRestrictedAttributes } from "../../interfaces/StudyRecordInterface";
import { paths } from "../../lib/api/v1";

type Props = {
  sectionId: string;
  queryParams: paths[Url]["get"]["parameters"]["query"];
  enabled: boolean;
};

type SectionTimelineResponse =
  paths[Url]["get"]["responses"]["200"]["content"]["application/json"];
export type SectionTimelineResponseData =
  SectionTimelineResponse["studyRecords"]["data"][0];

const URL = "/api/v1/sections/{section_id}/timeline";
type Url = typeof URL;

export const useFetchSectionsTimeline = ({
  sectionId,
  queryParams,
  enabled,
}: Props) => {
  const { data, ...result } = useInfiniteQuery<
    SectionTimelineResponse,
    HTTPErrors
  >({
    queryKey: [URL, sectionId, queryParams],
    queryFn: ({ pageParam }) =>
      fetchSectionsTimeline({
        sectionId,
        queryParams: { ...queryParams, since: pageParam as string | undefined },
      }),
    initialPageParam: null,
    getNextPageParam: ({ studyRecords }) => studyRecords.meta.till,
    enabled,
  });

  const queryClient = useQueryClient();

  const updateStudyRecordQueryCache = (
    newStudyRecord: StudyRecordWithRestrictedAttributes,
  ) => {
    queryClient.setQueriesData<InfiniteData<SectionTimelineResponse>>(
      { queryKey: [URL, sectionId, queryParams] },
      (oldData) => {
        if (!oldData) {
          return {
            pages: [],
            pageParams: [],
          } as InfiniteData<SectionTimelineResponse>;
        }

        const result = {
          ...oldData,
          pages: oldData.pages.map((page) => {
            return {
              ...page,
              studyRecords: {
                data: page.studyRecords.data.map((oldStudyRecord) =>
                  oldStudyRecord.id === newStudyRecord.id
                    ? newStudyRecord
                    : oldStudyRecord,
                ),
                meta: page.studyRecords.meta,
              },
            };
          }),
        };

        return result as InfiniteData<SectionTimelineResponse>;
      },
    );
  };
  return {
    data: data?.pages.flatMap((page) => page.studyRecords.data),
    meta: data?.pages.flatMap((page) => page.studyRecords.meta)[0],
    updateStudyRecordQueryCache,
    ...result,
  };
};

const fetchSectionsTimeline = async ({
  sectionId,
  queryParams,
}: Pick<Props, "sectionId" | "queryParams">) => {
  const { data, response } = await boronClient.GET(URL, {
    params: {
      path: { section_id: sectionId },
      query: queryParams,
    },
  });

  if (response.ok && data) {
    return data;
  }

  throw await createError(response);
};
