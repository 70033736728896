import classNames from "classnames";
import { Table } from "../../components/features/AnalyticsTable";
import { StudentFreePlanNotice } from "../../components/features/StudentFreePlanNotice";
import {
  DrillSectionRetentionLevel,
  StudentDrillRetentionLevel,
} from "../../domains/StudentDrillRetentionLevel";
import TimeHelper from "../../helpers/TimeHelper";
import StudentInterface from "../../interfaces/StudentInterface";
import { RetentionLevelCell } from "./RetentionLevelCell";

type Props = {
  data: StudentDrillRetentionLevel[];
  student: StudentInterface;
};
export const RetentionLevelTable = ({ data, student }: Props) => {
  return (
    // NOTE: セクション別定着の列の高さを行の高さと合わせるため、tableの高さを100%にしたりtrの高さを1pxにしたりしています
    // Refs: https://stackoverflow.com/questions/3542090/how-to-make-div-fill-td-height/3542470#3542470
    <Table className="h-full">
      <thead className="text-sm">
        <tr className="h-4 whitespace-nowrap border-0 border-b border-solid border-gray-400">
          <th>教材名</th>
          <th>総学習時間</th>
          <th>全体定着率</th>
          <th>セクション別定着</th>
        </tr>
      </thead>

      <tbody className="divide-x-0 divide-y divide-solid divide-gray-400 text-black">
        {student.billingPlan == "free" ? (
          <TableRowFreePlan student={student} />
        ) : data.length ? (
          data.map((item: StudentDrillRetentionLevel) => (
            <TableRow
              key={item.publicId}
              item={item}
              isInactive={student.status === "inactive"}
            />
          ))
        ) : (
          <TableRowNoData />
        )}
      </tbody>
    </Table>
  );
};

const TableRow = ({
  item,
  isInactive,
}: {
  item: StudentDrillRetentionLevel;
  isInactive: boolean;
}) => {
  const isGrayedOut = isInactive || !item.shelved;
  const lastStudiedAt =
    item.lastStudiedAt !== null
      ? TimeHelper.fullFormat(item.lastStudiedAt)
      : "－";
  return (
    // NOTE: グレーアウト時にborderの色が変わらないようにopacityを指定しています
    <tr className={classNames("h-[1px]", { "bg-gray-200": isGrayedOut })}>
      <td
        className={classNamesWithGrayOut(
          "w-auto max-w-[20rem] break-words px-7 py-4 text-left md:min-w-[12rem]",
          isGrayedOut,
        )}
      >
        {item.name}
        <div className="mt-2 text-sm text-gray-900">
          {`最終学習時間：${lastStudiedAt}`}
        </div>
      </td>
      <td
        className={classNamesWithGrayOut(
          "w-[8rem] whitespace-nowrap md:px-4",
          isGrayedOut,
        )}
      >
        {TimeHelper.secondsToDisplayTime(item.totalDuration)}
      </td>
      <td
        className={classNamesWithGrayOut(
          "w-[8rem] whitespace-nowrap md:px-4",
          isGrayedOut,
        )}
      >
        {toPercent(item.retentionRate)}
      </td>
      <td className="h-full w-[80rem] max-w-[80rem] border-0 border-l border-solid border-gray-400 p-0">
        <div className="grid h-full grid-cols-[repeat(auto-fill,minmax(10rem,1fr))]">
          {item.drillSections.map(
            (drillSection: DrillSectionRetentionLevel, index: number) => {
              return (
                <RetentionLevelCell
                  key={index}
                  drillSectionName={drillSection.name}
                  duration={drillSection.duration}
                  progress={{
                    perfectCount: drillSection.retentionCount.perfectCount,
                    establishedCount:
                      drillSection.retentionCount.establishedCount,
                    studyingCount: drillSection.retentionCount.studyingCount,
                    notStudiedCount:
                      drillSection.retentionCount.notStudiedCount,
                  }}
                  isGrayedOut={isGrayedOut}
                />
              );
            },
          )}
        </div>
      </td>
    </tr>
  );
};

const TableRowNoData = () => {
  return (
    <tr>
      <td colSpan={4} className="py-7 text-center">
        教材が登録されていません
      </td>
    </tr>
  );
};

const TableRowFreePlan = ({ student }: Pick<Props, "student">) => {
  return (
    <tr>
      <td colSpan={4} className="py-7 text-center">
        <StudentFreePlanNotice
          lastName={student.lastName}
          firstName={student.firstName}
          sectionId={student.section.id}
          description="にすると学習状況が閲覧できます。"
        />
      </td>
    </tr>
  );
};

const toPercent = (rate: number) => {
  const percent = Math.round(rate * 100);

  return `${percent}%`;
};

const classNamesWithGrayOut = (className: string, isGrayedOut: boolean) =>
  classNames(className, { "opacity-50": isGrayedOut });
