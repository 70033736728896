import { useMutation, useQuery } from "@tanstack/react-query";
import queryString from "query-string";
import ApiClient from "../../api";
import {
  BookshelfEntry,
  BookshelfStatusType,
} from "../../domains/BookshelfEntry";
import { HTTPErrors, createError } from "../../errors";

type FetchMaterialQueryOptions = {
  type?: "all";
};
const ERROR_MESSAGE =
  "教材データの取得に失敗しました。しばらくしてから再読込してください。";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFetchBookshelfEntries = (
  studentId: string,
  options?: FetchMaterialQueryOptions,
) => {
  const query = queryString.stringify(options as Record<string, string>);
  const baseKey = `students/${studentId}/learning_materials`;
  const keys = query ? [baseKey, query] : [baseKey];
  const result = useQuery<readonly BookshelfEntry[], HTTPErrors>({
    queryKey: keys,
    queryFn: async () => {
      const res = await ApiClient.get(
        `/api/v1/students/${studentId}/learning_materials`,
        {
          query: `?${query}`,
        },
      );

      if (res.ok) {
        const json = await res.json();
        return toBookshelfEntries(json);
      }
      const error = await createError(res);
      throw error;
    },
    retry: false,
  });

  return result;
};

// NOTE:
// APIレスポンスの形だとlearningMaterial部分のキーが分かれていないため,
// ここで型を変換している
type BookshelfEntryResponse = {
  code: string;
  imageUrl: string;
  unit: string;
  title: string;
  status: BookshelfStatusType;
  statusHuman: string;
  categoryName: string;
  isDeletable: boolean;
};
const toBookshelfEntry = (
  bookshelfEntryResponse: BookshelfEntryResponse,
): BookshelfEntry => ({
  learningMaterial: {
    name: bookshelfEntryResponse.title,
    code: bookshelfEntryResponse.code,
    imageUrl: bookshelfEntryResponse.imageUrl,
    unit: bookshelfEntryResponse.unit,
  },
  categoryName: bookshelfEntryResponse.categoryName,
  status: bookshelfEntryResponse.status,
  statusHuman: bookshelfEntryResponse.statusHuman,
  isDeletable: bookshelfEntryResponse.isDeletable,
});
const toBookshelfEntries = (
  responseData: Record<string, any>,
): readonly BookshelfEntry[] => {
  if (
    !responseData.learningMaterials ||
    !Array.isArray(responseData.learningMaterials)
  ) {
    console.error("invalid data error", responseData);
    throw new Error(ERROR_MESSAGE);
  }
  return responseData.learningMaterials.map((entry) => toBookshelfEntry(entry));
};

type UseRemoveBookshelfEntryProps = {
  studentId: string;
  steakMaterialCode: string;
  onError: (errors: HTTPErrors) => void;
  onSuccess: () => void;
};

export const useRemoveBookshelfEntry = ({
  studentId,
  steakMaterialCode,
  onError,
  onSuccess,
}: UseRemoveBookshelfEntryProps) => {
  return useMutation<void, HTTPErrors, void>({
    mutationFn: async () => {
      const res = await ApiClient.delete(
        `/api/v1/students/${studentId}/learning_materials/${steakMaterialCode}`,
      );
      if (res.ok) {
        return;
      }
      throw await createError(res);
    },
    onSuccess,
    onError,
  });
};
