import { Button } from "@studyplus/boron-ui";
import * as React from "react";
import { BlockRow } from "../../../components/atoms/BlockRow";
import Checkbox from "../../../components/atoms/Checkbox";
import ErrorText from "../../../components/atoms/ErrorText";
import { Flex } from "../../../components/atoms/Flex";
import Icon from "../../../components/atoms/Icon";
import Input from "../../../components/atoms/Input";
import LinkButton from "../../../components/atoms/LinkButton";
import Loader from "../../../components/atoms/Loader";
import { TimeInput } from "../../../components/atoms/TimeInput";
import { LoadableLearningMaterialSelect } from "../../../components/features/LoadableLearningMaterial";
import { LoadableStudentsMultiSelect } from "../../../components/features/LoadableStudentsMultiSelect";
import {
  FieldLayoutMultiRows,
  FormFooter,
  ScheduleDatePicker,
  ScheduleFormLabel,
  ScheduleTimeFieldLayout,
  ScheduleTimeHStack,
  StackForm,
  StackItem,
  StudyPlanAmountInput,
  StudyPlanDurationInput,
  StudyPlanFieldStack,
  StudyPlanUnitTextWrapper,
} from "../../../components/features/Schedule/FormParts";
import { StudentTagForm } from "../../../components/features/StudentTagModalBody";
import { SelectWrapper } from "../../../components/general/SelectWrapper";
import { Text } from "../../../components/general/Text";
import { SectionSchedule } from "../../../domains/SectionSchedule";
import { useModalContext } from "../modal";
import styles from "./SectionScheduleForm.scss";
import { useSectionScheduleForm } from "./useSectionScheduleForm";

type SectionScheduleFormFieldsProps = {
  sectionSchedule?: SectionSchedule | null;
  initialDate?: Date;
};

export const SectionScheduleFormFields = ({
  sectionSchedule,
  initialDate,
}: SectionScheduleFormFieldsProps) => {
  const {
    section,
    isLoading,
    lectureOptions,
    classroomOptions,
    teacherOptions,
    onSubmit,
    summaryProps,
    startDateProps,
    endDateProps,
    getResourceProps,
    getScheduleTimeProps,
    getStudySchedulePlanValue,
    alldayProps,
    studentProps,
    learningMaterialProps,
    isSetMaterial,
    unitText,
    isAllday,
    isMutating,
    endAtErrorText,
    summaryErrorText,
    resourceErrorText,
  } = useSectionScheduleForm({
    sectionSchedule: sectionSchedule ?? null,
    initialDate: initialDate,
  });

  const modalState = useModalContext();

  if (isLoading || !lectureOptions || !classroomOptions || !teacherOptions) {
    return <Loader loading />;
  }

  if (modalState.isStudentTag) {
    return (
      <StudentTagForm submitCallback={modalState.edit} {...studentProps} />
    );
  }

  return (
    <StackForm
      onSubmit={onSubmit}
      className={styles.sectionScheduleFormWrapper}
    >
      <StackItem>
        <Input
          {...summaryProps}
          placeholder="タイトルを入力"
          aria-label="タイトルを入力"
          aria-required="true"
        />
        {summaryErrorText && (
          <SectionScheduleErrorText>
            {summaryErrorText}
          </SectionScheduleErrorText>
        )}
      </StackItem>
      <StackItem>
        <ScheduleTimeFieldLayout>
          <Icon name="icon-timeline" color="primary" />
          <Flex wrap marginTop="0" gap="1">
            <Flex alignItems="center" marginTop="0">
              <ScheduleTimeHStack>
                <ScheduleDatePicker {...startDateProps} noIcon />
                {!isAllday && (
                  <TimeInput
                    {...getScheduleTimeProps("startTime")}
                    aria-label="開始時刻"
                  />
                )}
                <div>〜</div>
              </ScheduleTimeHStack>
            </Flex>
            <Flex marginTop="0" alignItems="center">
              {isAllday && <ScheduleDatePicker {...endDateProps} noIcon />}
              {!isAllday && (
                <TimeInput
                  {...getScheduleTimeProps("endTime")}
                  aria-label="終了時刻"
                />
              )}
            </Flex>
          </Flex>
          {endAtErrorText && (
            <SectionScheduleErrorText>
              <>{String(endAtErrorText)}</>
            </SectionScheduleErrorText>
          )}
          <BlockRow marginTop="1.5rem">
            <Checkbox
              id="isWholeDay"
              strokeColorLevel="normal"
              {...alldayProps}
            >
              終日
            </Checkbox>
          </BlockRow>
        </ScheduleTimeFieldLayout>
      </StackItem>
      <StackItem>
        <FieldLayoutMultiRows>
          <Icon name="icon-students" color="primary" />
          <Flex justifyContent="space-between">
            <ScheduleFormLabel htmlFor="students-select">
              対象者を選択
            </ScheduleFormLabel>
            <LinkButton onClick={modalState.openStudentTags}>
              <Icon name="icon-plus-tag" color="primary" />
              <Text color="primary" bold>
                生徒タグを使って選択
              </Text>
            </LinkButton>
          </Flex>
          <BlockRow marginTop="0.8rem">
            <LoadableStudentsMultiSelect
              {...studentProps}
              placeholder="対象者を選択"
              size="md"
              section={section}
            />
          </BlockRow>
          {resourceErrorText && (
            <SectionScheduleErrorText>
              {resourceErrorText}
            </SectionScheduleErrorText>
          )}
        </FieldLayoutMultiRows>
      </StackItem>
      <StackItem>
        <FieldLayoutMultiRows>
          <Icon name="icon-door" color="primary" />
          <SelectWrapper
            {...getResourceProps("classroom", classroomOptions)}
            placeholder="教室を選択"
            aria-label="教室を選択"
            options={classroomOptions}
            noOptionsMessage={() => "教室を登録することで設定できます"}
            size="md"
            isClearable={true}
          />
          {resourceErrorText && (
            <SectionScheduleErrorText>
              {resourceErrorText}
            </SectionScheduleErrorText>
          )}
        </FieldLayoutMultiRows>
      </StackItem>
      <StackItem>
        <FieldLayoutMultiRows>
          <Icon name="icon-school-mini" color="primary" />
          <SelectWrapper
            {...getResourceProps("teacher", teacherOptions)}
            placeholder="講師を選択"
            aria-label="講師を選択"
            options={teacherOptions}
            noOptionsMessage={() => "講師を登録することで設定できます"}
            size="md"
            isClearable={true}
          />
          {resourceErrorText && (
            <SectionScheduleErrorText>
              {resourceErrorText}
            </SectionScheduleErrorText>
          )}
        </FieldLayoutMultiRows>
      </StackItem>
      <StackItem>
        <FieldLayoutMultiRows>
          <Icon name="icon-textbooks" color="primary" />
          <SelectWrapper
            {...getResourceProps("lecture", lectureOptions)}
            placeholder="講座を選択"
            aria-label="講座を選択"
            noOptionsMessage={() => "講座を登録することで設定できます"}
            options={lectureOptions}
            size="md"
            isClearable={true}
          />
        </FieldLayoutMultiRows>
      </StackItem>
      <StackItem>
        <FieldLayoutMultiRows>
          <Icon name="icon-textbook" color="primary" />
          <ScheduleFormLabel htmlFor="material-select">
            学習教材設定
          </ScheduleFormLabel>
          <BlockRow marginTop="0.8rem">
            <LoadableLearningMaterialSelect
              {...learningMaterialProps}
              placeholder="教材を選択"
              section={section}
            />
          </BlockRow>
          {isSetMaterial && (
            <Flex wrap marginTop="0rem">
              <StudyPlanFieldStack>
                <ScheduleFormLabel htmlFor="study-schedule-hours" size="xs">
                  学習時間
                </ScheduleFormLabel>
                <Flex alignItems="center" marginTop="0.4rem">
                  <StudyPlanDurationInput>
                    <Input
                      id="number-of-hours"
                      type="number"
                      min="0"
                      max="9999"
                      {...getStudySchedulePlanValue("numberOfHours")}
                      aria-label="学習時間(時間)"
                    />
                  </StudyPlanDurationInput>
                  <StudyPlanUnitTextWrapper>時間</StudyPlanUnitTextWrapper>
                  <StudyPlanDurationInput>
                    <Input
                      type="number"
                      id="number-of-minutes"
                      min="0"
                      max="59"
                      {...getStudySchedulePlanValue("numberOfMinutes")}
                      aria-label="学習時間(分)"
                    />
                  </StudyPlanDurationInput>
                  <StudyPlanUnitTextWrapper>分</StudyPlanUnitTextWrapper>
                </Flex>
              </StudyPlanFieldStack>
              <StudyPlanFieldStack>
                <ScheduleFormLabel htmlFor="studyAmountField" size="xs">
                  学習量
                </ScheduleFormLabel>
                <Flex marginTop="0.4rem" alignItems="center">
                  <StudyPlanAmountInput>
                    <Input
                      type="number"
                      min="0"
                      id="amount"
                      {...getStudySchedulePlanValue("amount")}
                    />
                  </StudyPlanAmountInput>
                  <StudyPlanUnitTextWrapper>
                    {unitText}
                  </StudyPlanUnitTextWrapper>
                </Flex>
              </StudyPlanFieldStack>
            </Flex>
          )}
        </FieldLayoutMultiRows>
      </StackItem>

      <FormFooter>
        <Button
          isLoading={isMutating}
          disabled={isMutating}
          type="submit"
          className={styles.submitButton}
        >
          {sectionSchedule?.id ? "更新" : "登録"}
        </Button>
      </FormFooter>
    </StackForm>
  );
};

const SectionScheduleErrorText = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  return <ErrorText className={styles.errorText}>{children}</ErrorText>;
};
