import { IconLock } from "@studyplus/boron-ui";
import type { StudyRecordWithRestrictedAttributes } from "../../../../interfaces/StudyRecordInterface";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../../../general/Popover/Popover";
import styles from "./styles.scss";
const nl2br = require("react-nl2br");

type Props = {
  studyRecord: StudyRecordWithRestrictedAttributes;
};

export const Comment = ({ studyRecord }: Props) => {
  if (studyRecord.restrictedAttributes.includes("comment")) {
    return <RestrictedComment />;
  }

  return (
    <div className={styles.comment} data-testid="timeline-item-memo">
      {nl2br(studyRecord.comment)}
    </div>
  );
};

const RestrictedComment = () => {
  return (
    <PopoverProvider>
      <Popover>
        <PopoverTrigger className="cursor-pointer text-gray-800">
          <IconLock className="mr-2 align-middle" />
          <span className="align-middle">要点・ひとことメモ</span>
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent>
            <div className="text-center">
              プロプランにすると生徒が記録した
              <br />
              全学習記録が閲覧できます
            </div>
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </PopoverProvider>
  );
};
