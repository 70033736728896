import { useField } from "formik";
import { StaffBoardPost } from "../../../interfaces/StaffBoardPostInterface";
import DeleteIcon from "../../atoms/DeleteIcon";
import Icon from "../../atoms/Icon";
import { FormValues } from "./useStaffBoardPostEditForm";

type StaffBoardPostAttachments = StaffBoardPost["attachments"];

type Props = {
  staffBoardPostAttachmentFiles: StaffBoardPostAttachments;
  setHiddenAttachmentIds: React.Dispatch<React.SetStateAction<string[]>>;
};

export const StaffBoardAttachmentFiles = ({
  staffBoardPostAttachmentFiles,
  setHiddenAttachmentIds,
}: Props) => {
  const [field, _, helpers] =
    useField<FormValues["deleteAttachments"]>("deleteAttachments");
  const { value: deleteAttachments } = field;
  const { setValue } = helpers;

  return (
    <ul>
      {staffBoardPostAttachmentFiles.map((staffBoardPostAttachmentFile) => {
        return (
          <li
            className="mt-6 flex items-center"
            key={staffBoardPostAttachmentFile.id}
          >
            <DeleteIcon
              onDelete={(e) => {
                e.preventDefault();

                const targetAttachment = staffBoardPostAttachmentFiles.find(
                  (attachment) =>
                    attachment.id === staffBoardPostAttachmentFile.id,
                );
                if (targetAttachment) {
                  setValue([...deleteAttachments, { id: targetAttachment.id }]);
                }

                setHiddenAttachmentIds((prev) => [
                  ...prev,
                  staffBoardPostAttachmentFile.id,
                ]);
              }}
            />
            <a
              href={staffBoardPostAttachmentFile.url}
              target="_blank"
              rel="noopener noreferrer"
              className="ml-5 inline-flex items-center text-blue-600 fill-blue-600 font-normal hover:text-blue-800"
            >
              <Icon name="icon-file" />
              <span className="ml-5">
                {staffBoardPostAttachmentFile.filename}
              </span>
            </a>
          </li>
        );
      })}
    </ul>
  );
};
