import * as React from "react";
import { boronClient } from "../../api.ts";
import { FSHTTPError, UnprocessableEntityError } from "../../errors.ts";
import { useBoronMutation } from "../../hooks/http/useBoronMutation.ts";
import { useFlashMessage } from "../../hooks/useFlashMessage.ts";
import { HTTP_ERROR_MESSAGE } from "../../reducers";

export const useRegisterLearningMaterial = () => {
  const [
    registeringLearningMaterialCodes,
    setRegisteringLearningMaterialCodes,
  ] = React.useState<string[]>([]);
  const [registeredLearningMaterialCodes, setRegisteredLearningMaterialCodes] =
    React.useState<string[]>([]);

  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const { mutate } = useBoronMutation(
    async ({
      studentId,
      materialCode,
    }: { studentId: string; materialCode: string }) =>
      await boronClient.POST(
        "/api/v1/students/{student_id}/learning_materials",
        {
          params: {
            path: {
              student_id: studentId,
            },
          },
          body: {
            material_code: materialCode,
          },
        },
      ),
    {
      onSuccess: (_, { materialCode }) => {
        showSuccessMessage("生徒の本棚に登録しました");
        setRegisteredLearningMaterialCodes((prev) => [...prev, materialCode]);
      },
      onError: (error) => {
        if (error instanceof UnprocessableEntityError) {
          // 配布されていないデジタル教材を登録しようとした際に発生する
          showErrorMessage("この教材を利用するには、事前申し込みが必要です。");
        } else if (
          error instanceof FSHTTPError ||
          error.message === HTTP_ERROR_MESSAGE // リクエストしたが、APIのレスポンスステータスが401,403,404,422,503の場合以外、messageがHTTP_ERROR_MESSAGEになる
        ) {
          showErrorMessage("生徒の本棚に登録できませんでした");
        } else {
          // HTTP以外のエラーの場合だが、従来と同じエラーメッセージを表示する
          showErrorMessage(HTTP_ERROR_MESSAGE);
        }
      },
      onSettled: (_, __, { materialCode }) => {
        setRegisteringLearningMaterialCodes((prev) =>
          prev.filter((code) => code !== materialCode),
        );
      },
    },
  );

  const handleRegister = (studentId: string, materialCode: string) => {
    if (registeringLearningMaterialCodes.includes(materialCode)) {
      return;
    }

    setRegisteringLearningMaterialCodes((prev) => [...prev, materialCode]);

    mutate({ studentId, materialCode });
  };

  return {
    registeringLearningMaterialCodes,
    registeredLearningMaterialCodes,
    handleRegister,
  };
};
