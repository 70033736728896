import { Link } from "react-router-dom";
import Card from "../../../components/atoms/Card";
import Loader from "../../../components/atoms/Loader";
import { useQueryError } from "../../../hooks/http/useQueryError.ts";
import StudentInterface from "../../../interfaces/StudentInterface.ts";
import StudentPreRegisteredLearningMaterialInterface from "../../../interfaces/StudentPreRegisteredLearningMaterialInterface.ts";
import styles from "./styles.scss";
import { useDeleteLearningMaterialPreRegistration } from "./useDeleteLearningMaterialPreRegistration.ts";
import { useFetchLearningMaterialPreRegistrations } from "./useFetchLearningMaterialPreRegistrations.ts";

interface Props {
  student: StudentInterface;
}

const StudentLearningMaterialsPreRegistrations = (props: Props) => {
  const { data, isPending, error } = useFetchLearningMaterialPreRegistrations({
    studentId: props.student.id,
  });
  useQueryError(error);

  const { mutate } = useDeleteLearningMaterialPreRegistration({
    studentId: props.student.id,
  });

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <p className={styles.studentStatus}>
          生徒アプリ連携状況：{props.student.statusHuman}
        </p>
        <Link
          to={`/students/${props.student.id}/learning_materials/pre_registrations/new`}
          className={"buiButtonBase buiButtonVariantFilled"}
        >
          新規登録
        </Link>
      </div>
      <LearningMaterialList
        isLoading={isPending}
        data={data}
        onDelete={mutate}
      />
    </div>
  );
};

const LearningMaterialList = ({
  isLoading,
  data,
  onDelete,
}: {
  isLoading: boolean;
  data: StudentPreRegisteredLearningMaterialInterface[] | undefined;
  onDelete: (learningMaterialCode: string) => void;
}) => {
  if (!data) {
    return <Loader loading={isLoading} />;
  }

  if (data.length > 0) {
    return (
      <div className={styles.learningMaterials}>
        {data.map(
          (learningMaterial: StudentPreRegisteredLearningMaterialInterface) => (
            <LearningMaterialCard
              learningMaterial={learningMaterial}
              onDelete={onDelete}
              key={`StudentBookshelf-Bookshelf-learningMaterial-${learningMaterial.code}`}
            />
          ),
        )}
      </div>
    );
  } else {
    return <p>登録済みの教材がありません</p>;
  }
};

const LearningMaterialCard = ({
  learningMaterial,
  onDelete,
}: {
  learningMaterial: StudentPreRegisteredLearningMaterialInterface;
  onDelete: (learningMaterialCode: string) => void;
}) => {
  const handleDelete = () => {
    onDelete(learningMaterial.code);
  };

  return (
    <Card
      title={learningMaterial.title}
      image={learningMaterial.imageUrl}
      key={`StudentBookshelf-Bookshelf-learningMaterial-${learningMaterial.code}`}
      showDeleteIcon={true}
      onDelete={handleDelete}
    />
  );
};

export default StudentLearningMaterialsPreRegistrations;
