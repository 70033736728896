import { Button } from "@studyplus/boron-ui";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import { RequestBody } from "../../../features/SectionsStaffBoardPostsPage/SectionStaffBoardPosts/useCreateStaffBoardPost";
import ErrorText from "../../atoms/ErrorText";
import Input from "../../atoms/Input";
import Label from "../../atoms/Label";
import { MainSection } from "../../atoms/MainSection";
import Textarea from "../../atoms/Textarea";
import { ApiError, ApiErrorMessages } from "../StaffBoardPost/ApiErrorMessages";
import { InputFileList } from "../StaffBoardPost/InputFileList";
import { validationSchema } from "../StaffBoardPostForm/validationSchema";

type Props = {
  submitting: boolean;
  operatorName: string;
  onSubmit: (values: RequestBody, resetForm: () => void) => void;
  apiError: ApiError;
};

export type Values = {
  title: string;
  name: string;
  comment: string;
  attachments: File[];
};

export const StaffBoardPostCreateForm = ({
  submitting,
  operatorName,
  onSubmit,
  apiError,
}: Props) => {
  const initialValues = {
    title: "",
    name: operatorName,
    comment: "",
    attachments: [],
  };

  const handleSubmit = (
    values: Values,
    formikHelpers: FormikHelpers<Values>,
  ) => {
    const params: Omit<RequestBody, "attachments"> & {
      attachments: { file: File }[];
    } = {
      title: values.title,
      name: values.name,
      comment: values.comment,
      attachments: values.attachments.map((attachment) => {
        return {
          file: attachment,
        };
      }),
    };
    onSubmit(params, formikHelpers.resetForm);
  };

  const addInputFile =
    (formik: FormikProps<Values>) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.currentTarget.files) return;

      const file = e.currentTarget.files[0];
      const lastIndex = formik.values.attachments
        ? formik.values.attachments.length
        : 0;
      formik.setFieldValue(`attachments[${lastIndex}]`, file);
    };

  const removeInputFlie = (formik: FormikProps<Values>) => (index: number) => {
    const { setFieldValue } = formik;
    if (formik.values.attachments) {
      formik.values.attachments.splice(index, 1);
      setFieldValue(`attachments`, formik.values.attachments);
    }
  };

  return (
    <div className="mt-7">
      <MainSection>
        <Formik<Values>
          initialValues={initialValues}
          initialErrors={undefined}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formikProps) => (
            <Form aria-label="スタッフボード投稿フォーム">
              <div className="flex w-full mb-3">
                <div className="w-full">
                  <Label htmlFor="staff_board_post__title">タイトル</Label>
                  <Field
                    id="staff_board_post__title"
                    name="title"
                    component={Input}
                    placeholder="タイトルを入力"
                  />
                  <ErrorMessage
                    name="title"
                    component={ErrorText}
                    className="text-red-400 text-sm"
                  />
                </div>
                <div className="w-[34rem] ml-3">
                  <Label htmlFor="staff_board_post__name">スタッフ名</Label>
                  <Field
                    id="staff_board_post__name"
                    name="name"
                    component={Input}
                    placeholder="スタッフ名を入力"
                  />
                  <ErrorMessage
                    name="name"
                    component={ErrorText}
                    className="text-red-400 text-sm"
                  />
                </div>
              </div>
              <Label htmlFor="staff_board_post__comment">コメント</Label>
              <Field
                id="staff_board_post__comment"
                name="comment"
                component={Textarea}
                rows={4}
              />
              <ErrorMessage
                name="comment"
                component={ErrorText}
                className="text-red-400 text-sm"
              />
              <ApiErrorMessages apiError={apiError} />
              <div className="flex justify-end items-center mt-4 gap-10">
                <InputFileList
                  inputFiles={formikProps.values.attachments}
                  addInputFile={addInputFile(formikProps)}
                  removeInputFile={removeInputFlie(formikProps)}
                />
                <Button
                  className="w-[9.2rem]"
                  type="submit"
                  disabled={!formikProps.isValid || submitting}
                  isLoading={submitting}
                >
                  送信
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </MainSection>
    </div>
  );
};
