import queryString from "query-string";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useQueryString = <T = string>(): Record<string, T> => {
  const locationState = useLocation();
  return useMemo(
    () => queryString.parse(locationState.search, { arrayFormat: "bracket" }),
    [locationState.search],
  ) as unknown as Record<string, T>;
};
