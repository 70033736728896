import queryString from "query-string";
import ApiClient from "../api";
import { GetRequestOptions } from "../api";
import { TagFormInterface } from "../interfaces/TagFormInterface";

// APIからタグ全件欲しいときは十分に大きい数字をperに入れて投げるという仕様
const MAX_PER = 1000000000;

const SectionTagApi = {
  interruptGetTags: (
    sectionId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.interruptGet(
      `/api/v1/sections/${sectionId}/all_tags`,
      options,
    );
  },

  getTags: (
    sectionId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.get(`/api/v1/sections/${sectionId}/all_tags`, options);
  },

  getAllTags: (sectionId: string): Promise<Response> => {
    const params = { per: MAX_PER, active_student: true };
    const query = `?${queryString.stringify(params)}`;

    return ApiClient.get(`/api/v1/sections/${sectionId}/all_tags`, { query });
  },

  createTag: (
    sectionId: string,
    params: TagFormInterface,
  ): Promise<Response> => {
    return ApiClient.post(`/api/v1/sections/${sectionId}/tags`, {
      tag: params,
    });
  },

  updateTag: (
    sectionId: string,
    tagId: string,
    params: TagFormInterface,
  ): Promise<Response> => {
    return ApiClient.patch(`/api/v1/sections/${sectionId}/tags/${tagId}`, {
      tag: params,
    });
  },

  deleteTag: (sectionId: string, tagId: string): Promise<Response> => {
    return ApiClient.delete(`/api/v1/sections/${sectionId}/tags/${tagId}`);
  },
};

export default SectionTagApi;
