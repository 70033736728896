import { Button } from "@studyplus/boron-ui";
import { LearningMaterial as LearningMaterialType } from "../../../features/StudentsLearningMaterialsNewPage";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../../general/Popover/Popover.tsx";
import styles from "./styles.scss";

export const LearningMaterial = ({
  learningMaterial,
  registeredLearningMaterialCodes,
  handleRegisterLearningMaterial,
  disabled,
}: {
  learningMaterial: LearningMaterialType;
  registeredLearningMaterialCodes: string[];
  handleRegisterLearningMaterial: (
    learningMaterial: LearningMaterialType,
  ) => () => void;
  disabled: boolean;
}) => {
  return (
    <li className={styles.searchResult__learningMaterial}>
      {learningMaterial.imageUrl && <img src={learningMaterial.imageUrl} />}
      <h3 className={styles.searchResult__learningMaterial__name}>
        {learningMaterial.title}
      </h3>
      {registeredLearningMaterialCodes.includes(learningMaterial.code) ? (
        <Button
          disabled={true}
          className={styles.searchResult__learningMaterial__button}
        >
          登録済
        </Button>
      ) : !learningMaterial.isBookshelfRegistrable ? (
        <PopoverProvider>
          <Popover>
            <PopoverTrigger asChild className="cursor-not-allowed">
              <span className="w-full sm:w-auto">
                <Button
                  disabled={true}
                  className={`pointer-events-none ${styles.searchResult__learningMaterial__button}`}
                >
                  配信枠終了
                </Button>
              </span>
            </PopoverTrigger>
            <PopoverPortal>
              <PopoverContent side="top">
                <div className="text-center text-sm font-normal">
                  生徒配信枠利用数の
                  <br />
                  上限に達しています
                </div>
              </PopoverContent>
            </PopoverPortal>
          </Popover>
        </PopoverProvider>
      ) : (
        <Button
          disabled={disabled}
          variant="outline"
          className={styles.searchResult__learningMaterial__button}
          onClick={handleRegisterLearningMaterial(learningMaterial)}
        >
          本棚に登録
        </Button>
      )}
    </li>
  );
};
