import StaffBoardPostItem from "../../../../components/features/StaffBoardPostItem";
import OperatorInterface from "../../../../interfaces/OperatorInterface.ts";
import SectionInterface from "../../../../interfaces/SectionInterface.ts";
import { StaffBoardPost } from "../../../../interfaces/StaffBoardPostInterface.ts";
import styles from "./styles.scss";

interface Props {
  section: SectionInterface;
  currentOperator: OperatorInterface;
  staffBoardPosts: StaffBoardPost[];
  deletePost: (staffBoardPostId: string) => void;
}

const StaffBoardPostList = (props: Props) => {
  if (props.staffBoardPosts.length === 0) return null;

  return (
    <div className={styles.root}>
      {props.staffBoardPosts.map((post: StaffBoardPost) => (
        <StaffBoardPostListItem
          section={props.section}
          currentOperator={props.currentOperator}
          staffBoardPost={post}
          deletePost={props.deletePost}
          key={`staff_board_post-${post.id}`}
        />
      ))}
    </div>
  );
};

const StaffBoardPostListItem = (props: {
  currentOperator: OperatorInterface;
  section: SectionInterface;
  staffBoardPost: StaffBoardPost;
  deletePost: (staffBoardPostId: string) => void;
}) => {
  const handleDeletePost = () => {
    props.deletePost(props.staffBoardPost.id);
  };

  return (
    <StaffBoardPostItem
      section={props.section}
      currentOperator={props.currentOperator}
      staffBoardPost={props.staffBoardPost}
      deletePost={handleDeletePost}
    />
  );
};

export default StaffBoardPostList;
