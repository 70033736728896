import classNames from "classnames";
import { StudyRecordWithRestrictedAttributes } from "../../../../../interfaces/StudyRecordInterface";
import Icon from "../../../../atoms/Icon";
import LinkButton from "../../../../atoms/LinkButton";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../../../../general/Popover/Popover";

type Props = {
  restrictedAttributes: StudyRecordWithRestrictedAttributes["restrictedAttributes"];
  totalComments: number;
  noLabel?: boolean;
  toggleVisibility: () => void;
};

export const TimelineCommentButton = ({
  restrictedAttributes,
  totalComments,
  noLabel = false,
  toggleVisibility,
}: Props) => {
  const isRestricted = restrictedAttributes.includes("comments");

  if (isRestricted) {
    return <RestrictedTimelineCommentButton noLabel={noLabel} />;
  } else {
    return (
      <NormalTimelineCommentButton
        totalComments={totalComments}
        noLabel={noLabel}
        toggleVisibility={toggleVisibility}
      />
    );
  }
};

const RestrictedTimelineCommentButton = ({
  noLabel,
}: Pick<Props, "noLabel">) => {
  return (
    <PopoverProvider>
      <Popover>
        <PopoverTrigger asChild>
          <LinkButton type="button">
            <div className="cursor-not-allowed opacity-50">
              <Icon
                name={"icon-action-comment-default"}
                className="mr-[0.2rem] h-3 w-3 align-middle"
              />

              {!noLabel && <span className="align-middle">コメント</span>}
            </div>
          </LinkButton>
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent>
            <div className="text-center">
              プロプランにすると学習記録に
              <br />
              「コメント」ができます。
            </div>
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </PopoverProvider>
  );
};

const NormalTimelineCommentButton = ({
  totalComments,
  noLabel = false,
  toggleVisibility,
}: Pick<Props, "totalComments" | "noLabel" | "toggleVisibility">) => (
  <LinkButton type="button" onClick={toggleVisibility}>
    <span
      className={classNames("inline-flex items-center", {
        "text-blue-400": totalComments > 0,
      })}
    >
      <Icon
        name={
          totalComments > 0
            ? "icon-action-comment-selected"
            : "icon-action-comment-default"
        }
        className="mr-[0.2rem] h-3 w-3"
      />

      {totalComments > 0 ? totalComments : !noLabel && "コメント"}
    </span>
  </LinkButton>
);
