import * as React from "react";
import { useEffect } from "react";
import StudentNewLearningMaterial from "../../components/features/StudentNewLearningMaterial";
import { OutletContextProps } from "../../helpers/RouterHelper.tsx";
import enhanceStudentsPage from "../../hocs/enhanceStudentsPage.tsx";
import { useQueryError } from "../../hooks/http/useQueryError.ts";
import StudentInterface from "../../interfaces/StudentInterface.ts";
import { paths } from "../../lib/api/v1";
import { useFetchAllStudentLearningMaterials } from "./useFetchAllStudentLearningMaterials.tsx";
import { useRegisterLearningMaterial } from "./useRegisterLearningMaterial.ts";

interface Props extends OutletContextProps {
  student: StudentInterface;
}

export type LearningMaterial =
  paths["/api/v1/sections/{section_id}/learning_materials/search"]["get"]["responses"]["200"]["content"]["application/json"]["learningMaterials"]["data"][0];

const StudentsLearningMaterialsNewPage: React.FC<Props> = (props) => {
  useEffect(() => {
    props.setActiveMenu("text");
  }, []);

  const {
    data: registeredLearningMaterials,
    isPending: isLoadingStudentLearningMaterials,
    error,
  } = useFetchAllStudentLearningMaterials({ studentId: props.student.id });
  useQueryError(error);

  const {
    registeringLearningMaterialCodes,
    registeredLearningMaterialCodes,
    handleRegister,
  } = useRegisterLearningMaterial();

  const allRegisteredLearningMaterialCodes = registeredLearningMaterials
    ? [
        ...registeredLearningMaterials.map(
          (learningMaterial) => learningMaterial.code,
        ),
        ...registeredLearningMaterialCodes,
      ]
    : undefined;

  return (
    <StudentNewLearningMaterial
      student={props.student}
      isLoading={isLoadingStudentLearningMaterials}
      registeredLearningMaterialCodes={allRegisteredLearningMaterialCodes}
      registeringLearningMaterialCodes={registeringLearningMaterialCodes}
      onRegister={handleRegister}
      isPreRegistration={false}
    />
  );
};

const EnhancedPage = enhanceStudentsPage(StudentsLearningMaterialsNewPage, {
  title: "テキスト",
});

export default EnhancedPage;
