import * as yup from "yup";

export const validationSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .required("タイトルを入力してください")
    .max(64, "タイトルは64文字以内で入力してください"),
  name: yup
    .string()
    .trim()
    .required("スタッフ名を入力してください")
    .max(33, "スタッフ名は33文字以内で入力してください"),
  comment: yup.string().trim().required("コメントを入力してください"),
});
