import { paths } from "../../../lib/api/v1";

export type ApiError =
  | paths["/api/v1/sections/{section_id}/staff_board_posts"]["post"]["responses"]["422"]["content"]["application/json"]
  | null;

type ApiErrorProps = {
  apiError: ApiError;
};

/**
 * APIエラーメッセージを表示するコンポーネント
 * 主にprivate_content_attachmentsのエラーを表示するために使用
 */
export const ApiErrorMessages: React.FC<ApiErrorProps> = ({ apiError }) => {
  if (!apiError || !apiError.errors || apiError.errors.length === 0) {
    return null;
  }

  return (
    <div className="mt-2">
      {apiError.errors.map((error, index) => (
        <div key={index} className="text-red-400 text-sm">
          {error.field === "private_content_attachments" && (
            <p>{error.message}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default ApiErrorMessages;
