import * as React from "react";

import {
  IconActionLikeDefault,
  IconActionLikeSelected,
} from "@studyplus/boron-ui";
import { useFetchSectionsTimeline } from "../../../../../features/SectionsTimelinePage/useFetchSectionsTimeline";
import { useFetchSectionsTimelineSummary } from "../../../../../features/SectionsTimelineSummaryPage/useFetchSectionsTimelineSummary";
import DeviceHelper from "../../../../../helpers/DeviceHelper";
import {
  EventType,
  sendUserEvent,
} from "../../../../../helpers/GoogleAnalyticsHelper";
import OperatorInterface from "../../../../../interfaces/OperatorInterface";
import StudentInterface from "../../../../../interfaces/StudentInterface";
import type { StudyRecordWithRestrictedAttributes } from "../../../../../interfaces/StudyRecordInterface";
import LinkButton from "../../../../atoms/LinkButton";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../../../../general/Popover/Popover";
import {
  useDeleteStudyRecordLike,
  usePostStudyRecordLike,
} from "./useMutateStudyRecordLike";

type Props = {
  currentOperator: OperatorInterface;
  studyRecord: StudyRecordWithRestrictedAttributes;
  student: StudentInterface;
  canSendComment: boolean;
  noLabel?: boolean;
  updateStudyRecordQueryCache:
    | ReturnType<
        typeof useFetchSectionsTimelineSummary
      >["updateStudyRecordQueryCache"]
    | ReturnType<
        typeof useFetchSectionsTimeline
      >["updateStudyRecordQueryCache"];
};

export const TimelineLikeButton = (props: Props) => {
  const { isRestricted, toggleLike } = useTimelineLikeButton(props);
  const noLabel = props.noLabel || false;

  return isRestricted ? (
    <RestrictedLikeButton>
      <ButtonContent
        noLabel={noLabel}
        likedOperators={props.studyRecord.likedOperators}
        currentOperator={props.currentOperator}
      />
    </RestrictedLikeButton>
  ) : (
    <LikedOperatorsPopover likedOperators={props.studyRecord.likedOperators}>
      <LinkButton
        color={
          props.studyRecord.likedOperators.some(
            (likedOperator) =>
              likedOperator.id === props.currentOperator.publicId,
          )
            ? "primary"
            : "black-lighten-2"
        }
        onClick={toggleLike}
        disabled={!props.canSendComment || isRestricted}
      >
        <ButtonContent
          noLabel={noLabel}
          likedOperators={props.studyRecord.likedOperators}
          currentOperator={props.currentOperator}
        />
      </LinkButton>
    </LikedOperatorsPopover>
  );
};

const useTimelineLikeButton = (props: Props) => {
  const isRestricted =
    props.studyRecord.restrictedAttributes.includes("likedOperators");

  const { mutate: mutatePost, isPending: isPostSubmitting } =
    usePostStudyRecordLike({
      studentId: props.student.id,
      studyRecordId: props.studyRecord.id,
      onSuccess: () => {
        props.updateStudyRecordQueryCache({
          ...props.studyRecord,
          likedOperators: [
            ...props.studyRecord.likedOperators,
            {
              id: props.currentOperator.publicId,
              lastName: props.currentOperator.lastName,
              firstName: props.currentOperator.firstName,
            },
          ],
        });
        sendUserEvent({
          type: EventType.LikeStudyRecord,
          studentId: props.student.id,
          studyRecordId: props.studyRecord.id,
        });
      },
    });

  const { mutate: mutateDelete, isPending: isDeleteSubmitting } =
    useDeleteStudyRecordLike({
      studentId: props.student.id,
      studyRecordId: props.studyRecord.id,
      onSuccess: () => {
        props.updateStudyRecordQueryCache({
          ...props.studyRecord,
          likedOperators: props.studyRecord.likedOperators.filter(
            (likedOperator) =>
              likedOperator.id !== props.currentOperator.publicId,
          ),
        });
        sendUserEvent({
          type: EventType.UnlikeStudyRecord,
          studentId: props.student.id,
          studyRecordId: props.studyRecord.id,
        });
      },
    });

  const toggleLike = (e: React.MouseEvent) => {
    if (isPostSubmitting || isDeleteSubmitting || !props.canSendComment) return;

    if (
      props.studyRecord.likedOperators.some(
        (likedOperator) => likedOperator.id === props.currentOperator.publicId,
      )
    ) {
      mutateDelete();
    } else {
      mutatePost();
    }
    // ドロワー表示のイベントを発火させないようにしています
    e.stopPropagation();
  };

  return {
    isRestricted,
    toggleLike,
  };
};

const ButtonContent = ({
  noLabel,
  likedOperators,
  currentOperator,
}: {
  noLabel: boolean;
  likedOperators: StudyRecordWithRestrictedAttributes["likedOperators"];
  currentOperator: OperatorInterface;
}) => {
  const LikedCount = () => {
    if (likedOperators.length === 0) return null;
    return (
      <span className="align-middle" aria-label="いいね">
        {likedOperators.length}
      </span>
    );
  };

  return (
    <>
      {likedOperators.some(
        (likedOperator) => likedOperator.id === currentOperator.publicId,
      ) ? (
        <>
          <IconActionLikeSelected className="mr-[0.2rem] align-middle" />
          <LikedCount />
        </>
      ) : (
        <>
          <IconActionLikeDefault className="mr-[0.2rem] align-middle" />
          <LikedCount />
        </>
      )}
      {!noLabel && likedOperators.length == 0 && (
        <span className="align-middle" aria-label="いいね">
          いいね！
        </span>
      )}
    </>
  );
};

const LikedOperatorsPopover = ({
  children,
  likedOperators,
}: React.PropsWithChildren<{
  likedOperators: StudyRecordWithRestrictedAttributes["likedOperators"];
}>) => {
  if (likedOperators.length === 0) return children;

  const likedOperatorNames = likedOperators
    .map(
      (likedOperator) => `${likedOperator.lastName} ${likedOperator.firstName}`,
    )
    .join(", ");
  return (
    <PopoverProvider hasOverlay={DeviceHelper.isTouchDevice()}>
      <Popover>
        <PopoverTrigger asChild>{children}</PopoverTrigger>
        <PopoverPortal>
          <PopoverContent side="top">
            <div className="text-left w-[16rem]">
              {likedOperatorNames} さんがいいねをしています
            </div>
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </PopoverProvider>
  );
};

const RestrictedLikeButton = ({ children }: React.PropsWithChildren) => {
  return (
    <PopoverProvider>
      <Popover>
        <PopoverTrigger asChild>
          <LinkButton color={"black-lighten-2"}>
            <div className="cursor-not-allowed opacity-50">{children}</div>
          </LinkButton>
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent>
            <div className="text-center">
              プロプランにすると学習記録に
              <br />
              「いいね」ができます。
            </div>
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </PopoverProvider>
  );
};
