import * as yup from "yup";

export const MAX_DRILL_REGISTRATION_CODE_COUNT = 100;

export const drillRegistrationCodeFormSchema = yup.object({
  drillRegistrationCodesString: yup
    .string()
    .trim()
    .required("コードを入力してください。")
    .test(
      "maxLines",
      `最大${MAX_DRILL_REGISTRATION_CODE_COUNT}件まで登録できます。`,
      (value) => {
        if (!value) return true;

        return (
          value.split("\n").filter((code) => code.trim().length > 0).length <=
          MAX_DRILL_REGISTRATION_CODE_COUNT
        );
      },
    ),
});

export type DrillRegistrationCodeFormValues = yup.InferType<
  typeof drillRegistrationCodeFormSchema
>;
