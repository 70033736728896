type Props = {
  dataTypeLabel: string;
};

export const Failure = ({ dataTypeLabel }: Props) => {
  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-8 rounded-lg bg-gray-100 px-10 py-[22px]">
        <div className="flex flex-col gap-8">
          <p className="font-bold text-red-400">
            {dataTypeLabel}の作成に失敗しました。
          </p>
          <p>サポートチームまでお問い合わせください。</p>
        </div>
      </div>
    </div>
  );
};
