import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { usePrompt } from "../../../hooks/usePrompt";
import Textarea from "../Textarea";
import { useResumableTextarea } from "./useResumableTextarea";

type Props = Parameters<typeof Textarea>[0] & {
  value: string;
  storageKey: string;
  isResumed: boolean;
  resumedValue: string | null;
  saveValue: ReturnType<typeof useResumableTextarea>["saveValue"];
  isBlockingNavigation: ReturnType<
    typeof useResumableTextarea
  >["isBlockingNavigation"];
  setNavigationBlocker: ReturnType<
    typeof useResumableTextarea
  >["setNavigationBlocker"];
};

const ResumableTextarea: React.FC<Props> = (props) => {
  const {
    value,
    onChange,
    storageKey,
    isResumed,
    resumedValue,
    saveValue,
    isBlockingNavigation,
    setNavigationBlocker,
    ...childProps
  } = props;

  usePrompt("このページから移動してもよろしいですか？", isBlockingNavigation);

  const valueProp = "field" in childProps ? childProps.field.value : value;
  const formikOnChange =
    "field" in childProps ? childProps.field.onChange : undefined;

  const [isChanged, setIsChanged] = useState<boolean>(false);

  // 値の変更時にLocalStorageに保存する
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setIsChanged(true);
      saveValue(e.target.value);

      if (formikOnChange) {
        formikOnChange(e);
      }
      if (onChange) {
        onChange(e);
      }
    },
    [setIsChanged, saveValue, formikOnChange, onChange],
  );

  useEffect(() => {
    setIsChanged(false);
  }, [storageKey]);

  const shouldDisplayResumedValue =
    isResumed && resumedValue !== null && !isChanged;

  const displayValue = shouldDisplayResumedValue
    ? resumedValue || ""
    : valueProp;

  // Textarea に渡すpropsを上書き
  const textareaProps =
    "field" in childProps
      ? {
          ...childProps,
          readOnly: !isResumed,
          value: displayValue,
          field: {
            ...childProps.field,
            value: displayValue,
            onChange: handleChange,
          },
        }
      : {
          ...childProps,
          readOnly: !isResumed,
          value: displayValue,
          onChange: handleChange,
        };

  return (
    <React.Fragment>
      <Textarea {...textareaProps} />
    </React.Fragment>
  );
};

export default ResumableTextarea;
