import * as React from "react";
import { useNavigate } from "react-router";
import BackLink from "../../../components/atoms/BackLink";
import Loader from "../../../components/atoms/Loader";
import { StaffBoardPostEditForm } from "../../../components/features/StaffBoardPostEditForm";
import StaffBoardPostForm from "../../../components/features/StaffBoardPostForm/index.tsx";
import { isPreReleaseEnvironment } from "../../../helpers/Settings.ts";
import { useFetchStaffBoardPost } from "../../../hooks/http/useFetchStaffBoardPost.ts";
import { useQueryError } from "../../../hooks/http/useQueryError.ts";
import { useFlashMessage } from "../../../hooks/useFlashMessage.ts";
import OperatorInterface from "../../../interfaces/OperatorInterface.ts";
import SectionInterface from "../../../interfaces/SectionInterface.ts";
import { useUpdateStaffBoardPost } from "./useUpdateStaffBoardPost.ts";

interface Props {
  section: SectionInterface;
  currentOperator: OperatorInterface;
  staffBoardPostId: string;
}

const SectionsStaffBoardPostEdit = (props: Props) => {
  const { section, currentOperator, staffBoardPostId } = props;

  const { isPending, data, error } = useFetchStaffBoardPost({
    sectionId: section.id,
    staffBoardPostId,
    disableCache: true, // キャッシュがあると、フォームに変更前の内容が表示されることがあるので無効化する
  });
  useQueryError(error);

  // TODO: feature flag外したら削除する
  const navigate = useNavigate();
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const { mutate, isPending: isSubmitting } = useUpdateStaffBoardPost({
    sectionId: section.id,
    staffBoardPostId,
    onSuccess: () => {
      showSuccessMessage("スタッフボードを更新しました");
      navigate(`/sections/${section.id}/staff_board_posts`);
    },
    onError: () => {
      showErrorMessage("スタッフボードを更新できませんでした");
    },
  });
  // TODO: feature flag外したら削除する

  return (
    <React.Fragment>
      <BackLink>戻る</BackLink>
      {isPending || !data ? (
        <Loader loading />
      ) : isPreReleaseEnvironment() ? (
        <StaffBoardPostEditForm
          sectionId={section.id}
          staffBoardPost={data}
          operatorName={currentOperator.fullName}
        />
      ) : (
        <StaffBoardPostForm
          staffBoardPost={data}
          submitting={isSubmitting}
          operatorName={currentOperator.fullName}
          onSubmit={(values, resetForm) => {
            mutate(values);
            resetForm();
          }}
        />
      )}
    </React.Fragment>
  );
};

export default SectionsStaffBoardPostEdit;
