import { useState } from "react";
import * as yup from "yup";
import { StaffBoardPost } from "../../../interfaces/StaffBoardPostInterface";
import { isImageAttachment } from "../StaffBoardPost/isImageAttachment";

type Props = {
  staffBoardPost: StaffBoardPost;
  operatorName: string;
};

export type FormValues = {
  title: string;
  name: string;
  comment: string;
  postAttachments: { file: File }[];
  deleteAttachments: { id: string }[];
};

const staffBoardPostEditFormSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .required("タイトルを入力してください")
    .max(64, "タイトルは64文字以内で入力してください"),
  name: yup
    .string()
    .trim()
    .required("スタッフ名を入力してください")
    .max(33, "スタッフ名は33文字以内で入力してください"),
  comment: yup.string().trim().required("コメントを入力してください"),
});

export const useStaffBoardPostEditForm = ({
  staffBoardPost,
  operatorName,
}: Props) => {
  // 添付ファイルの削除はバックエンドで行うため、フロントエンドでは非表示にするだけにとどめる
  const [hiddenAttachmentIds, setHiddenAttachmentIds] = useState<string[]>([]);

  const staffBoardPostAttachmentImages = staffBoardPost.attachments
    .filter((attachment) => !hiddenAttachmentIds.includes(attachment.id))
    .filter((attachment) => isImageAttachment(attachment));

  const staffBoardPostAttachmentFiles = staffBoardPost.attachments
    .filter((attachment) => !hiddenAttachmentIds.includes(attachment.id))
    .filter((attachment) => !isImageAttachment(attachment));

  const initialValues: FormValues = {
    title: staffBoardPost?.title ?? "",
    name: operatorName,
    comment: staffBoardPost?.comment ?? "",
    postAttachments: [],
    deleteAttachments: [],
  };

  const convertFormValuesToRequestValues = (values: FormValues) => {
    const { postAttachments, deleteAttachments, ...rest } = values;

    return {
      ...rest,
      attachments: [
        ...postAttachments.map((attachment) => ({
          file: attachment.file,
        })),
        ...deleteAttachments.map((attachment) => ({
          id: attachment.id,
          destroy: true,
        })),
      ],
    };
  };

  return {
    staffBoardPostAttachmentImages,
    staffBoardPostAttachmentFiles,
    initialValues,
    staffBoardPostEditFormSchema,
    convertFormValuesToRequestValues,
    setHiddenAttachmentIds,
  };
};
