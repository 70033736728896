import queryString from "query-string";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { BookshelfStatusType } from "../../../domains/BookshelfEntry";

export const useBookshelfStatusFromURL = () => {
  const location = useLocation();
  const type = queryString.parse(location.search).type as BookshelfStatusType;

  const defaultType = "in_progress";
  const [selectedType, setSelectedType] =
    useState<BookshelfStatusType>(defaultType);
  useEffect(() => {
    setSelectedType(type || defaultType);
  }, [type]);

  const navigate = useNavigate();
  const changeStatusType = (type: BookshelfStatusType) => {
    navigate({ search: `type=${type}` });
  };

  return {
    changeStatusType,
    selectedType,
  };
};
