import classNames from "classnames";
import * as React from "react";
import { useEffectAvoidFirst } from "../../../../../../hooks/useEffectAvoidFirst";
import StudentInterface from "../../../../../../interfaces/StudentInterface";
import type {
  StudyRecordCommentInterface,
  StudyRecordWithRestrictedAttributes,
} from "../../../../../../interfaces/StudyRecordInterface";
import { TimelineComment } from "./TimelineComment";
import { TimelineCommentForm } from "./TimelineCommentForm";
import styles from "./styles.scss";

interface Props {
  studyRecord: StudyRecordWithRestrictedAttributes;
  student: StudentInterface;
  canSendComment: boolean;
  isVisible: boolean;
  comments: StudyRecordCommentInterface[];
  comment: string;
  onAddComment: (comment: StudyRecordCommentInterface) => void;
  onDeleteComment: (comment: StudyRecordCommentInterface) => void;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  requireScrollIntoView: boolean;
}

export const TimelineComments = ({
  studyRecord,
  student,
  canSendComment,
  isVisible,
  comments,
  comment,
  onAddComment,
  onDeleteComment,
  setComment,
  requireScrollIntoView,
}: Props) => {
  const rootRef = React.useRef<HTMLDivElement>(null);
  useEffectAvoidFirst(() => {
    // NOTE: ドロワーで表示した際、いちばん下の記録のコメントを表示した時だけコメント欄が見えるようにスクロールする
    if (!requireScrollIntoView) return;
    if (isVisible && rootRef?.current) {
      rootRef.current.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  }, [requireScrollIntoView, isVisible, rootRef?.current, comments.length]);

  return (
    <div
      ref={rootRef}
      className={classNames(styles.root, {
        [styles.visible]: isVisible,
      })}
    >
      {comments.map((comment) => (
        <TimelineComment
          comment={comment}
          onDeleteComment={onDeleteComment}
          studyRecord={studyRecord}
          studentId={student.id}
          key={`TimelineComment-${student.id}-${comment.id}`}
        />
      ))}
      <TimelineCommentForm
        studyRecord={studyRecord}
        student={student}
        canSendComment={canSendComment}
        onAddComment={onAddComment}
        comment={comment}
        setComment={setComment}
      />
    </div>
  );
};
