import classNames from "classnames";
import { useField } from "formik";
import { useMemo } from "react";
import { MAX_DRILL_REGISTRATION_CODE_COUNT } from "./drillRegistrationCode";

export const DrillRegistrationCodeCounter = () => {
  const [field, _] = useField("drillRegistrationCodesString");
  const { value: drillRegistrationCodesString } = field;

  const drillRegistrationCodeCount = useMemo(() => {
    return drillRegistrationCodesString
      .split("\n")
      .filter((code: string) => code.trim().length > 0).length;
  }, [drillRegistrationCodesString]);

  return (
    <p className="text-lg text-black">
      <span
        className={classNames({
          "font-bold text-red-400":
            drillRegistrationCodeCount > MAX_DRILL_REGISTRATION_CODE_COUNT,
        })}
      >
        {drillRegistrationCodeCount}
      </span>
      /100件
    </p>
  );
};
