import { useField } from "formik";
import { StaffBoardPost } from "../../../interfaces/StaffBoardPostInterface";
import SquareImageList from "../../atoms/SquareImageList";
import { FormValues } from "./useStaffBoardPostEditForm";

type StaffBoardPostAttachments = StaffBoardPost["attachments"];

type Props = {
  staffBoardPostAttachmentImages: StaffBoardPostAttachments;
  setHiddenAttachmentIds: React.Dispatch<React.SetStateAction<string[]>>;
};

export const StaffBoardAttachmentImages = ({
  staffBoardPostAttachmentImages,
  setHiddenAttachmentIds,
}: Props) => {
  const [field, _, helpers] =
    useField<FormValues["deleteAttachments"]>("deleteAttachments");
  const { value: deleteAttachments } = field;
  const { setValue } = helpers;

  return (
    <div>
      <SquareImageList
        keys={staffBoardPostAttachmentImages.map((attachment) => attachment.id)}
        imageUrls={staffBoardPostAttachmentImages.map(
          (attachment) => attachment.url,
        )}
        deletable={true}
        onDelete={(index) => (e) => {
          e.preventDefault();

          const targetAttachment = staffBoardPostAttachmentImages[index];
          if (targetAttachment) {
            setValue([...deleteAttachments, { id: targetAttachment.id }]);
          }

          setHiddenAttachmentIds((prev) => [
            ...prev,
            staffBoardPostAttachmentImages[index].id,
          ]);
        }}
      />
    </div>
  );
};
