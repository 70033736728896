import * as React from "react";

import {
  IconActionLikeDefault,
  IconActionLikeSelected,
} from "@studyplus/boron-ui";
import { useFetchSectionsTimelineSummary } from "../../../../../features/SectionsTimelineSummaryPage/useFetchSectionsTimelineSummary";
import {
  EventType,
  sendUserEvent,
} from "../../../../../helpers/GoogleAnalyticsHelper";
import StudentInterface from "../../../../../interfaces/StudentInterface";
import type { StudyRecordWithRestrictedAttributes } from "../../../../../interfaces/StudyRecordInterface";
import LinkButton from "../../../../atoms/LinkButton";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../../../../general/Popover/Popover";
import {
  useDeleteStudyRecordLike,
  usePostStudyRecordLike,
} from "./useMutateStudyRecordLike";

type Props = {
  studyRecord: StudyRecordWithRestrictedAttributes;
  student: StudentInterface;
  canSendComment: boolean;
  noLabel?: boolean;
  updateStudyRecordQueryCache?: ReturnType<
    typeof useFetchSectionsTimelineSummary
  >["updateStudyRecordQueryCache"];
};

const TimelineLikeButton = (props: Props) => {
  const { liked, isRestricted, toggleLike } = useTimelineLikeButton(props);
  const noLabel = props.noLabel || false;

  return isRestricted ? (
    <RestrictedLikeButton>
      <ButtonContent liked={liked} noLabel={noLabel} />
    </RestrictedLikeButton>
  ) : (
    <LinkButton
      color={liked ? "primary" : "black-lighten-2"}
      onClick={toggleLike}
      disabled={!props.canSendComment || isRestricted}
    >
      <ButtonContent liked={liked} noLabel={noLabel} />
    </LinkButton>
  );
};

const useTimelineLikeButton = (props: Props) => {
  const [liked, setLiked] = React.useState(props.studyRecord.liked);
  const isRestricted = props.studyRecord.restrictedAttributes.includes("liked");

  React.useEffect(() => {
    setLiked(props.studyRecord.liked);
  }, [props.studyRecord.liked]);

  const { mutate: mutatePost, isPending: isPostSubmitting } =
    usePostStudyRecordLike({
      studentId: props.student.id,
      studyRecordId: props.studyRecord.id,
      onSuccess: () => {
        setLiked(!liked);
        if (props.updateStudyRecordQueryCache) {
          // タイムラインサマリーのドロワーでいいねしたときに、ドロワー外の画面のいいねに反映するための処理
          props.updateStudyRecordQueryCache({
            ...props.studyRecord,
            liked: !liked,
          });
        }
        sendUserEvent({
          type: EventType.LikeStudyRecord,
          studentId: props.student.id,
          studyRecordId: props.studyRecord.id,
        });
      },
    });

  const { mutate: mutateDelete, isPending: isDeleteSubmitting } =
    useDeleteStudyRecordLike({
      studentId: props.student.id,
      studyRecordId: props.studyRecord.id,
      onSuccess: () => {
        setLiked(!liked);
        if (props.updateStudyRecordQueryCache) {
          // タイムラインサマリーのドロワーでいいねしたときに、ドロワー外の画面のいいねに反映するための処理
          props.updateStudyRecordQueryCache({
            ...props.studyRecord,
            liked: !liked,
          });
        }
        sendUserEvent({
          type: EventType.UnlikeStudyRecord,
          studentId: props.student.id,
          studyRecordId: props.studyRecord.id,
        });
      },
    });

  const toggleLike = (e: React.MouseEvent) => {
    if (isPostSubmitting || isDeleteSubmitting || !props.canSendComment) return;

    if (liked) {
      mutateDelete();
    } else {
      mutatePost();
    }
    // ドロワー表示のイベントを発火させないようにしています
    e.stopPropagation();
  };

  return {
    liked,
    isRestricted,
    toggleLike,
  };
};

const ButtonContent = ({
  liked = false,
  noLabel,
}: {
  liked: boolean | null;
  noLabel: boolean;
}) => {
  return (
    <>
      {liked ? (
        <IconActionLikeSelected className={`mr-[0.2rem] align-middle`} />
      ) : (
        <IconActionLikeDefault className={`mr-[0.2rem] align-middle`} />
      )}
      {!noLabel && <span className="align-middle">いいね！</span>}
    </>
  );
};

const RestrictedLikeButton = ({ children }: React.PropsWithChildren) => {
  return (
    <PopoverProvider>
      <Popover>
        <PopoverTrigger asChild>
          <LinkButton color={"black-lighten-2"}>
            <div className="cursor-not-allowed opacity-50">{children}</div>
          </LinkButton>
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent>
            <div className="text-center">
              プロプランにすると学習記録に
              <br />
              「いいね」ができます。
            </div>
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </PopoverProvider>
  );
};

export default TimelineLikeButton;
