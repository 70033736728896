import * as React from "react";
import { useParams } from "react-router";
import BackLink from "../../components/atoms/BackLink";
import Loader from "../../components/atoms/Loader";
import TimelineItem from "../../components/features/Timeline/TimelineItem";
import { OutletContextProps } from "../../helpers/RouterHelper";
import {
  AuthenticateRouterProps,
  AuthenticatedPageProps,
} from "../../hocs/enhanceAuthenticatedPage";
import enhanceStudentsPage from "../../hocs/enhanceStudentsPage";
import { useQueryError } from "../../hooks/http/useQueryError";
import StudentInterface from "../../interfaces/StudentInterface";
import { useFetchStudentStudyRecord } from "./useFetchStudentStudyRecord";

type Props = OutletContextProps &
  AuthenticateRouterProps &
  Pick<AuthenticatedPageProps, "currentOperator"> & {
    student: StudentInterface;
  };

const StudentsStudyRecordPage = (props: Props) => {
  const { currentOperator, student } = props;
  const { studyRecordId = "" } = useParams<{ studyRecordId: string }>();

  React.useEffect(() => {
    props.setActiveMenu("timeline");
  }, []);

  const { data, isLoading, error, updateStudyRecordQueryCache } =
    useFetchStudentStudyRecord({
      studentId: student.id,
      studyRecordId,
    });
  useQueryError(error);

  if (!data || !currentOperator) return null;

  return (
    <div>
      <BackLink>戻る</BackLink>
      <div className="mx-auto my-8 max-w-[104.6rem] overflow-hidden rounded-md border border-solid border-gray-400 bg-white">
        {isLoading ? (
          <Loader loading={true} />
        ) : (
          <TimelineItem
            currentOperator={currentOperator}
            student={student}
            sectionId={student.section.id}
            studyRecord={data}
            showHeader={true}
            canSendComment={student.canSendComment}
            updateStudyRecordQueryCache={updateStudyRecordQueryCache}
          />
        )}
      </div>
    </div>
  );
};

export default enhanceStudentsPage(StudentsStudyRecordPage, {
  title: "タイムライン",
});
