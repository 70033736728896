import { IconArrowRight } from "@studyplus/boron-ui";
import SectionInterface from "../../../interfaces/SectionInterface";
import { SectionOperatorInvitationInterface } from "../../../interfaces/SectionOperatorInvitationInterface";
import Icon from "../../atoms/Icon";
import LinkList from "../../atoms/LinkList";
import Loader from "../../atoms/Loader/index";
import styles from "./styles.scss";

interface Props {
  sections: SectionInterface[];
  sectionOperatorInvitations: SectionOperatorInvitationInterface[];
  loading: boolean;
}

const SectionsIndex = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className="grid grid-cols-3 items-center mb-10">
        {/* デザインで要素の配置を中央・右寄せにするためのdiv */}
        <div />
        <p className="text-center text-2xl font-bold text-black"> 校舎一覧 </p>
        <a
          href="/multi_section_batches"
          className="text-blue-400 hover:text-blue-300 group justify-self-end text-sm font-bold  flex items-center"
        >
          校舎横断操作
          <IconArrowRight className="text-blue-400 group-hover:text-blue-300" />
        </a>
      </div>
      {renderSectionOperatorInvitations(props.sectionOperatorInvitations)}
      {renderSections(props.sections, props.loading)}
      <Loader loading={props.loading} />
    </div>
  );
};

const renderSectionOperatorInvitations = (
  sectionOperatorInvitations: SectionOperatorInvitationInterface[],
) => {
  if (sectionOperatorInvitations.length === 0) return null;

  return (
    <div className={styles.invitations}>
      <LinkList>
        {sectionOperatorInvitations.map(
          (invitation: SectionOperatorInvitationInterface) => {
            return (
              <LinkList.Item
                key={`invitation-${invitation.id}`}
                to={`/invitations/${invitation.id}`}
                iconName="icon-attention"
                theme="main"
                hovered={true}
              >
                <span className={styles.invitationMain}>
                  <span>
                    <span className={styles.sectionName}>
                      {invitation.fullName}
                    </span>
                    からメンバー招待が届いています
                  </span>
                  <Icon name="icon-arrow-right" />
                </span>
              </LinkList.Item>
            );
          },
        )}
      </LinkList>
    </div>
  );
};
const renderSections = (sections: SectionInterface[], loading: boolean) => {
  if (sections.length === 0 && loading) return null;
  return (
    <LinkList>
      {sections.length > 0 ? (
        sections.map((section: SectionInterface) => {
          return (
            <LinkList.Item
              key={`section-${section.id}`}
              to={`/sections/${section.id}`}
              iconName="icon-navi-school"
              theme={"main"}
            >
              {section.fullName}
            </LinkList.Item>
          );
        })
      ) : (
        <LinkList.Item
          iconName="icon-attention"
          theme={"gray"}
          isDisabled={true}
        >
          所属している校舎がありません。
        </LinkList.Item>
      )}
    </LinkList>
  );
};

export default SectionsIndex;
