import { Button } from "@studyplus/boron-ui";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { useNavigate } from "react-router-dom";
import Textarea from "../../components/atoms/Textarea";
import { UnprocessableEntityError } from "../../errors";
import { useQueryError } from "../../hooks/http/useQueryError";
import { useFlashMessage } from "../../hooks/useFlashMessage";
import SectionInterface from "../../interfaces/SectionInterface";
import { DrillRegistrationCodeCounter } from "./DrillRegistrationCodeCounter";
import { ErrorMessagesFromServer } from "./ErrorMessagesFromServer";
import {
  DrillRegistrationCodeFormValues,
  drillRegistrationCodeFormSchema,
} from "./drillRegistrationCode";
import { usePostDrillRegistrationCodes } from "./usePostDrillRegistrationCodes";

export const DrillRegistrationCodeForm = ({
  section,
}: {
  section: SectionInterface;
}) => {
  const navigate = useNavigate();
  const { showSuccessMessage } = useFlashMessage();

  const { isPending, mutate, error } = usePostDrillRegistrationCodes({
    section,
    onSuccess: () => {
      showSuccessMessage("登録に成功しました");
      navigate(`/sections/${section.id}/settings/digital_learning_materials`);
    },
  });

  // 422エラーはフォームにエラー表示をするのでハンドリングしない
  const handlingError =
    error instanceof UnprocessableEntityError ? null : error;
  useQueryError(handlingError);

  return (
    <Formik<DrillRegistrationCodeFormValues>
      initialValues={{ drillRegistrationCodesString: "" }}
      onSubmit={(values) => {
        mutate(values.drillRegistrationCodesString.split("\n"));
      }}
      validationSchema={drillRegistrationCodeFormSchema}
    >
      {({ touched, errors }: FormikProps<DrillRegistrationCodeFormValues>) => (
        <Form aria-label="デジタル教材登録コード入力フォーム">
          <div className="mt-5 rounded-lg bg-gray-100 py-9 px-10 flex flex-col gap-7">
            <div className="flex justify-between">
              <p className="text-lg text-black">デジタル教材登録コード</p>
              <DrillRegistrationCodeCounter />
            </div>
            <ErrorMessage
              name="drillRegistrationCodesString"
              component="p"
              className="text-red-400"
            />
            <ErrorMessagesFromServer error={error} />
            <div style={{ fontFamily: "Roboto Mono" }}>
              <Field
                component={Textarea}
                className="min-w-full  min-h-[440px] resize-none"
                id="drillRegistrationCodesString"
                name="drillRegistrationCodesString"
                data-testid="drillRegistrationCodesString"
                disabled={isPending}
                placeholder="例：
ABC123456
DEF789012
GHI345678"
              />
            </div>
          </div>
          <div className="mt-7 flex justify-end">
            <div className="w-[10rem]">
              <Button
                type="submit"
                isLoading={isPending}
                disabled={
                  touched.drillRegistrationCodesString &&
                  errors.drillRegistrationCodesString !== undefined
                }
                isBlock
              >
                登録
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
