import styles from "./styles.scss";

export const Overlay = ({
  onClick,
  className,
}: {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
}) => {
  return <div className={`${styles.overlay} ${className}`} onClick={onClick} />;
};
