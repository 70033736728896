import { boronClient } from "../../../api.ts";
import { useBoronMutation } from "../../../hooks/http/useBoronMutation.ts";
import { useFlashMessage } from "../../../hooks/useFlashMessage.ts";
import { useRemoveLearningMaterialPreRegistrationCache } from "./useFetchLearningMaterialPreRegistrations.ts";

export const useDeleteLearningMaterialPreRegistration = ({
  studentId,
}: {
  studentId: string;
}) => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const { removeLearningMaterialPreRegistrationCache } =
    useRemoveLearningMaterialPreRegistrationCache({
      studentId,
    });

  return useBoronMutation(
    async (code: string) =>
      await boronClient.DELETE(
        "/api/v1/students/{student_id}/learning_materials/{material_code}/pre_registrations",
        {
          params: {
            path: {
              student_id: studentId,
              material_code: code,
            },
          },
        },
      ),
    {
      onSuccess: (_, code) => {
        showSuccessMessage("本棚から削除しました");
        removeLearningMaterialPreRegistrationCache(code);
      },
      onError: () => {
        showErrorMessage("本棚から削除できませんでした");
      },
    },
  );
};
